import { Alert, Col, Modal, Row, Switch, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { MouseEvent, useState } from "react";
import { BiCheckShield, BiCloudUpload, BiErrorCircle, BiTrash } from "react-icons/bi";
import { actions } from "../../../../utils/store";
import { uploadFile } from "../../../../utils/utils";

export type RegValue = {
  Activities: RegActValue[]
}
export type RegActValue = { Lines: RegActLineValue[] } & RegistrationActivity
export type RegActLineValue = { Date: moment.Moment | null } & RegistrationActivityLine

type Props = {
  visible: boolean
  afregning: Afregning | "allokering"
  reg: Registration
  values: RegValue
  onClose: () => any
  onSubmit: (lonseddel: boolean) => Promise<any>
}

export default function RegSubmitModal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [uploadNew, setUploadNew] = useState(false);

  if (!props.reg) return null;

  async function onSubmit() {
    setLoading(true);
    try {

      if (fileList.length > 0) {
        const lonseddelFile = fileList[0].originFileObj!;
        const uploadLink = await actions.registrations.getLonseddelUploadLink(props.reg.PersonID, props.reg.Month)
        await uploadFile(uploadLink, lonseddelFile);
        await props.onSubmit(true);
      }
      else {
        await props.onSubmit(false);
      }
      props.onClose();
    }
    finally {
      setLoading(false);
    }
  }

  function onFileRemove(e: MouseEvent<any>) {
    setFileList([]);
    e.stopPropagation();
  }

  let hasLines = false;
  if (props.values) {
    for (const actId in props.values.Activities) {
      const act = props.values.Activities[actId];
      if (act.Lines.length > 0) {
        hasLines = true;
        break;
      }
    }
  }

  const hasFiles = fileList.length ? true : false;
  const hasExistingFile = props.reg.Lonseddel;
  const askFile = props.afregning !== "standardsats" && (!hasExistingFile || uploadNew);

  const canIndsend =
    (props.afregning === "standardsats") ||
    (props.afregning === "faktisk-lon" && (!hasLines || hasFiles || (hasExistingFile && !uploadNew))) ||
    (props.afregning === "allokering" && fileList.length > 0);


  return (
    <Modal title="Indsend registrering" visible={props.visible} okText="Indsend registrering" cancelText="Fortryd"
      closable={!loading} cancelButtonProps={{ disabled: loading }} okButtonProps={{ loading: loading, disabled: !canIndsend }} onCancel={() => props.onClose()} onOk={onSubmit}>

      <Alert message="Når registreringen indsendes, vil den blive låst og kan derefter ikke længere ændres." type="warning" icon={<BiErrorCircle size={18} />} showIcon />

      {
        (props.afregning === "allokering" || (props.afregning === "faktisk-lon" && hasLines)) &&
        <>
          {
            (props.afregning === "faktisk-lon" && hasExistingFile) &&
            <Row style={{ marginTop: 20 }} justify="center" align="middle" gutter={10}>
              <Col><Row align="middle">Vil du indsende en ny lønseddel?</Row></Col>
              <Col><Row align="middle"><Switch checkedChildren="Ja" unCheckedChildren="Nej" checked={uploadNew} onChange={e => setUploadNew(e)} /></Row></Col>
            </Row>
          }
          {
            askFile &&
            <Row style={{ margin: "20px 0 0 0" }} justify="center">
              <Upload.Dragger disabled={loading} fileList={fileList} accept=".pdf" style={{
                width: 150, height: 150, padding: 5,
                borderColor: ["", "rgb(183, 235, 143)", "gray"][(fileList.length == 0 ? 0 : 1) + (loading ? 1 : 0)],
                background: ["#fafafa", "rgb(246, 255, 237)", "lightgray"][(fileList.length == 0 ? 0 : 1) + (loading ? 1 : 0)]
              }} multiple={false} maxCount={1} onChange={e => setFileList(e.fileList)} showUploadList={false}>
                {
                  fileList.length == 0 ?
                    <>
                      <BiCloudUpload size={42} />
                      <div style={{ fontWeight: "bold" }}>Lønseddel</div>
                      <div style={{ fontSize: 12 }}>Klik eller træk fil</div>
                    </>
                    :
                    <>
                      <BiCheckShield size={42} />
                      <div style={{ fontWeight: "bold" }}>Lønseddel</div>
                      <div style={{ fontSize: 12, overflowWrap: "anywhere" }}>{fileList[0].name}</div>
                      <BiTrash size={18} color={loading ? "gray" : "#ff3939"} style={{ position: "absolute", top: 5, right: 5 }} onClick={(e) => !loading && onFileRemove(e)} />
                    </>
                }
              </Upload.Dragger>
            </Row>
          }
        </>
      }

    </Modal>
  )
}