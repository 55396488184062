import { Button, DatePicker, Form, Input, message, Modal, Row, Switch } from "antd";
import { useState } from "react";
import { actions, useCurProject, useRoles } from "../../../../utils/store";
import { hasRole, momentToMonth, monthToMoment, projectEnd, projectStart } from "../../../../utils/utils";
import NumberInput from "../../../shared/NumberInput";

type Props = {
  visible: boolean
  onClose: () => any
}

export default function CreateActivityModal(props: Props) {
  const roles = useRoles();
  const proj = useCurProject();
  const [loading, setLoading] = useState(false);
  const [isProjMan, setIsProjMan] = useState(false);

  async function onCreate(data: any) {
    if (!proj) return;

    setLoading(true)
    try {
      let actID: string = data.Name.toLocaleLowerCase()
      actID = actID.replace(" ", "-")
      actID = actID.replace(/[^a-z0-9]/g, "")

      const activityID = `${proj.Alias}.${actID}`
      const start = data.Start ? momentToMonth(data.Start) : null;
      const end = data.End ? momentToMonth(data.End) : null;

      await actions.projects.createActivity({
        Name: data.Name,
        ActivityID: activityID,
        DeminimisEst: data.DeminimisEst,
        Start: start,
        End: end,
        ProjectManagement: isProjMan,
        Workdescrs: []
      })
      message.success("Aktiviteten er blevet oprettet")
      props.onClose()
    }
    finally {
      setLoading(false)
    }
  }


  if (!proj) return null


  const start = monthToMoment(projectStart);
  const end = monthToMoment(projectEnd);

  return (
    <Modal destroyOnClose visible={props.visible} title="Opret aktivitet" onCancel={() => props.onClose?.()} footer={false}>
      <Form labelCol={{ span: 8 }} onFinish={onCreate}>
        <Form.Item name="Name" label="Navn" rules={[{ required: true, message: "Påkrævet" }]}>
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item name="Start" label="Startmåned">
          <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMMM YYYY" placeholder={start.format("MMMM YYYY")}
            disabledDate={v => v.isBefore(start) || v.isAfter(end)}
          />
        </Form.Item>
        <Form.Item name="End" label="Slutmåned">
          <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMMM YYYY" placeholder={end.format("MMMM YYYY")}
            disabledDate={v => v.isBefore(start) || v.isAfter(end)}
          />
        </Form.Item>

        {
          hasRole(roles, "admin") &&
          <Form.Item label="Projektledelse">
            <Switch disabled={loading} checkedChildren="Ja" unCheckedChildren="Nej" checked={isProjMan} onChange={v => setIsProjMan(v)} />
          </Form.Item>
        }

        {
          (proj.Bevilling == "REACTRF-210029" && !isProjMan) &&
          <Form.Item name="DeminimisEst" label="Støtteestimat" rules={[{ required: true, message: "Påkrævet" }]}>
            <NumberInput addonAfter="Kr." disabled={loading} style={{ width: "100%" }} min={1} precision={2} />
          </Form.Item>
        }

        <Row justify="center">
          <Button type="primary" htmlType="submit">Opret</Button>
        </Row>
      </Form>
    </Modal>
  )
}