import { Select, Button, Cascader, Form, Input, Modal, Row, Tabs, Col, message, Checkbox, DatePicker, Divider, Alert, Tag } from "antd"
import { useForm } from "antd/lib/form/Form"
import moment from "moment"
import { useState } from "react"
import { BiError, BiTrash } from "react-icons/bi"
import { actions, useCurProject, useOrganisations, usePersons, useProjects, useRoles } from "../../../../utils/store"
import { momentToMonth, monthToMoment, projectAliasFromActivity, projectEnd, projectStart } from "../../../../utils/utils"
import CVRSearch from "./CVRSearch"

type Props = {
  type: "partner" | "participant" | "person"
  visible: boolean
  onClose: () => any
}

export default function InviteModal(props: Props) {
  if (props.type == "person")
    return <InvitePersonModal {...props} />
  return <InviteOrgModal {...props} />
}

function InvitePersonModal(props: Props) {
  const curProj = useCurProject();
  const pers = usePersons();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  if (curProj == null) return null

  async function onSubmit(values: any) {
    setLoading(true);
    try {
      let p = pers?.find(p => p.Email == values.email)
      if (p == null) {
        p = await actions.persons.invite({
          Email: values.email,
          Start: values.start ? momentToMonth(values.start) : null,
          End: values.end ? momentToMonth(values.end) : null
        })
        message.success("Personen er blevet inviteret")
      }
      await actions.persons.addToActivities(p.PersonID, values.acts)
      message.success("Personen er blevet tilføjet til aktiviteterne")
      props.onClose();
    }
    catch { }
    setLoading(false)
  }

  return (
    <Modal visible={props.visible} onCancel={props.onClose} title={`Invitér deltager - ${curProj.Name}`} footer={false}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item name="email" label="Email" rules={[{ required: true, message: "Påkrævet" }]}>
          <Input disabled={loading} type="email" />
        </Form.Item>
        <Row gutter={10}>
          <Col flex="50%">
            <Form.Item name="start" label="Start" initialValue={moment()} >
              <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMM YYYY" placeholder={monthToMoment(projectStart).format("MMM YYYY")} />
            </Form.Item>
          </Col>
          <Col flex="50%">
            <Form.Item name="end" label="Evt. slut">
              <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMM YYYY" placeholder={monthToMoment(projectEnd).format("MMM YYYY")} />
            </Form.Item>
          </Col>
        </Row>
        <Activities proj={curProj} loading={loading} type={props.type} existingActs={[]} />
      </Form>
    </Modal>
  )
}

function InviteOrgModal(props: Props) {
  const curProj = useCurProject();
  const projs = useProjects();
  const orgs = useOrganisations();

  const [orgType, setOrgType] = useState<OrganisationType | null>(null)
  const [pnoOptions, setPnoOptions] = useState<any[]>([]);
  const [pnoLoading, setPnoLoading] = useState(false);

  const [cvr, setCvr] = useState<string | null>(null);
  const [pno, setPno] = useState<string | null>(null);

  const [showPno, setShowPno] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showActs, setShowActs] = useState(false);

  const [existingActs, setExistingActs] = useState<string[]>([])

  const [form] = useForm()
  const [loading, setLoading] = useState(false);

  async function onCvrChange(cvr: string) {
    if (!curProj) return

    setCvr(cvr);
    setPno(null);
    setShowPno(false);
    setOrgType(null);
    setShowDetails(false);
    setExistingActs([]);
    setShowActs(false);

    if (cvr.length != 8) return;

    // Check for existing organisation
    const existing: Organisation | null = orgs?.find(o => o.CVR == cvr) || null

    form.setFieldsValue({
      "cvr": existing?.CVR || cvr,
      "pno": existing?.Pno,
      "acts": []
    });

    if (existing) {
      setPno(existing.Pno);
      setOrgType(existing.Type)

      // Check if type matches
      if (existing.Type == props.type ||
        existing.Type == "vip" && props.type == "partner") {

        const actIDs = existing.Activities?.
          filter(a => {
            const projAlias = projectAliasFromActivity(a.ActivityID);
            if (projAlias != curProj!.Alias) return false;
            return curProj.Alias == projAlias
          }).
          map(a => a.ActivityID)

        setExistingActs(actIDs || []);
        setShowActs(true);
      }
      return;
    }

    setOrgType(props.type as OrganisationType);

    // Load P-units
    setShowPno(true);
    setPnoLoading(true);
    try {
      const pUnits = await actions.organisations.listPUnits(cvr)
      setPnoOptions(pUnits)
    }
    finally {
      setPnoLoading(false);
    }
  }

  function onPnoChange(pno: string) {
    if (!curProj) return

    setPno(pno);
    setShowPno(true);
    setOrgType(null);
    setShowDetails(false);
    setShowActs(false);

    if (pno.length != 10) return;

    // Check for existing organisation
    const existing = orgs?.find(o => o.Pno === pno) || null;

    form.setFieldsValue({
      "pno": existing?.Pno || pno,
      "acts": []
    });

    if (existing) {
      setCvr(existing.CVR)
      setOrgType(existing.Type)

      // Check if type matches
      if (existing.Type == props.type ||
        existing.Type == "vip" && props.type == "partner") {

        const actIDs = existing.Activities?.
          filter(a => {
            const projAlias = projectAliasFromActivity(a.ActivityID);
            if (projAlias != curProj!.Alias) return false;
            return curProj.Alias == projAlias
          }).
          map(a => a.ActivityID)

        form.setFieldsValue({ "cvr": existing.CVR });

        setExistingActs(actIDs || []);
        setShowActs(true);
      }
      return;
    }

    setOrgType(props.type as OrganisationType);
    setShowDetails(true);
    setShowActs(true);
  }

  function onTabChange(key: string) {
    form.resetFields()
  }

  async function onSubmit(values: any) {
    if (!projs) return

    const existing = orgs?.find(o => o.CVR == cvr)
    if (existing) {
      setLoading(true);
      try {
        await actions.organisations.addToActivities(existing.OrganisationID, values.acts)
        message.success("Organisationen er blevet tilføjet til aktiviteterne")
        props.onClose()
      }
      finally {
        setLoading(false)
      }
    }
    else {
      const acts: string[] = Array.from(new Set(values.acts))
      const start: Month | null = values.start ? momentToMonth(values.start) : null
      const end: Month | null = values.end ? momentToMonth(values.end) : null

      setLoading(true)
      try {
        const org = await actions.organisations.create({
          Pno: values.pno,
          Type: orgType!,
          Afregning: values.afregning,
          Start: start,
          End: end,
        })
        await actions.organisations.addToActivities(org.OrganisationID, acts)
        await actions.organisations.inviteEmployee({
          OrganisationID: org.OrganisationID,
          Email: values.email,
          Start: null,
          End: null,
        })

        message.success("Organisationen er blevet inviteret")
        props.onClose()
      }
      finally {
        setLoading(false)
      }
    }
  }

  if (!projs || !curProj) return null;

  const regOpts: any[] = []
  const socOpts: any[] = [];

  if (orgType === "participant") {
    for (const p of projs) {
      if (p.Bevilling !== "REACTRF-210029") continue;
      const acts = [];

      for (const a of (p.Activities || [])) {
        if (!a.ProjectManagement)
          acts.push({ value: a.ActivityID, label: a.Name })
      }

      if (acts.length > 0)
        regOpts.push({
          value: p.Alias,
          label: p.Name,
          children: acts,
        });
    }
  }
  else {
    for (const p of projs) {
      const acts = [];

      for (const a of (p.Activities || [])) {
        if (a.ProjectManagement)
          acts.push({ value: a.ActivityID, label: a.Name })
      }

      if (acts.length > 0)
        regOpts.push({
          value: p.Alias,
          label: p.Name,
          children: acts,
        });
    }
  }

  for (const p of projs) {
    if (p.Bevilling !== "REACTSF-210031") continue;
    const acts = [];

    for (const a of (p.Activities || [])) {
      if (!a.ProjectManagement)
        acts.push({ value: a.ActivityID, label: a.Name })
    }

    if (acts.length > 0)
      socOpts.push({
        value: p.Alias,
        label: p.Name,
        children: acts,
      });
  }

  function Details() {
    if (!curProj) return null;

    return (
      <>
        <Row gutter={10}>
          <Col flex="50%">
            <Form.Item name="start" label="Start" initialValue={moment()} >
              <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMM YYYY" placeholder={monthToMoment(projectStart).format("MMM YYYY")} />
            </Form.Item>
          </Col>
          <Col flex="50%">
            <Form.Item name="end" label="Evt. slut">
              <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMM YYYY" placeholder={monthToMoment(projectEnd).format("MMM YYYY")} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="email" label="Email" rules={[{ required: true, message: "Påkrævet" }]}>
          <Input disabled={loading} type="email" />
        </Form.Item>
        <Form.Item name="afregning" label="Afregningsmetode" rules={[{ required: true, message: "Påkrævet" }]}>
          <Select disabled={loading} defaultActiveFirstOption>
            {(props.type == "partner" || curProj.Bevilling == "REACTRF-210029") && <Select.Option key="faktisk-lon">Faktisk Løn</Select.Option>}
            <Select.Option key="standardsats">Standardsats</Select.Option>
          </Select>
        </Form.Item>
      </>
    )
  }

  return (
    <Modal visible={props.visible} onCancel={props.onClose} title={`Invitér ${props.type == "participant" ? "deltager" : "partner"} - ${curProj.Name}`} footer={false}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Tabs onTabClick={onTabChange} destroyInactiveTabPane>
          <Tabs.TabPane tab="Søg" key="search">
            <Form.Item name="cvr" label="Organisation" rules={[{ required: true, message: "Påkrævet" }]}>
              <CVRSearch disabled={loading} value={cvr} onChange={onCvrChange} />
            </Form.Item>

            {
              showPno &&
              <Form.Item name="pno" label="P-enhed" rules={[{ required: true, message: "Påkrævet" }]}>
                <Select
                  placeholder={"Vælg afdeling"}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={null}
                  loading={pnoLoading}
                  optionLabelProp="title"
                  style={{ width: "100%" }}
                  disabled={!cvr || loading}
                  value={pno || undefined}
                  onChange={onPnoChange}
                >
                  {
                    pnoOptions.map(o =>
                      <Select.Option key={o.Pno} title={
                        <Row justify="space-between"><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col>P-nr: {o.Pno}</Col></Row>
                      }>
                        <Row justify="space-between" style={{ lineHeight: 1.2 }}><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col style={{ fontSize: 12, }}>P-nr: {o.Pno}</Col></Row>
                        <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Address}</Row>
                        <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Zip} {o.City}</Row>
                      </Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            }
          </Tabs.TabPane>
          <Tabs.TabPane tab="Indtast P-nummer" key="enter">
            <Form.Item name="pno" label="P-nummer" rules={[{ required: true, message: "Påkrævet" }, { len: 10, message: "Skal være 10 tegn" }]}>
              <Input disabled={loading} placeholder="Indtast P-nummer" style={{ width: "100%" }} value={pno || ""} onChange={e => onPnoChange(e.target.value)} />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
        {showDetails && <Details />}
        {showActs && <Activities proj={curProj} loading={loading} type={props.type} existingActs={existingActs} />}
        {
          (orgType && orgType !== props.type && !(orgType == "vip" && props.type == "partner")) &&
          <Alert type="error" description={`Organisationen er ikke en ${props.type == "participant" ? "deltager" : "partner"}`} icon={<BiError />} showIcon />
        }
      </Form>
    </Modal >
  )
}


type ActivitiesProps = {
  proj: Project
  loading: boolean
  type: "participant" | "partner" | "person"
  existingActs: string[]
}
function Activities(props: ActivitiesProps) {
  if (!props.proj) return null

  return (
    <>
      <Form.Item name="acts" label="Aktiviteter" rules={[{ required: true, message: "Påkrævet" }]} style={{ marginBottom: 0 }}>
        <Select
          disabled={props.loading}
          placeholder="Vælg aktiviteter"
          mode="multiple"
          options={
            (props.proj.Activities || [])
              .filter(a => props.type != "partner" ? !a.ProjectManagement : a.ProjectManagement)
              .map(a => ({ value: a.ActivityID, label: a.Name }))}
        />
      </Form.Item>
      <Row style={{ marginBottom: 20 }}>
        {
          props.existingActs.length > 0 &&
          <Row style={{ marginTop: 10 }} gutter={10}>
            <Col>Deltager allerede i:</Col>
            <Col>{props.existingActs.map(a => <Tag key={a} color="blue">{props.proj.Activities!.find(a2 => a2.ActivityID == a)!.Name}</Tag>)}</Col>
          </Row>
        }
      </Row>
      <Row justify="center">
        <Button htmlType="submit" type="primary" loading={props.loading} >Invitér</Button>
      </Row>
    </>
  )
}