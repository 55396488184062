import { Button, Form, Input, message, Modal, Row } from "antd"
import { useState } from "react"
import { actions, useCurActivity, useCurProject } from "../../../../utils/store"
import NumberInput from "../../../shared/NumberInput"

type Props = {
  visible: boolean
  onClose: () => any
}

export default function EditActivityModal(props: Props) {
  const act = useCurActivity();
  const proj = useCurProject();
  const [loading, setLoading] = useState(false);
  if (!act || !proj) return null;

  async function onSubmit(data: any) {
    if (!act) return;

    setLoading(true)
    try {
      await actions.projects.updateActivity({
        ActivityID: act.ActivityID,
        Name: data.Name,
        DeminimisEst: data.DeminimisEst,
        Workdescrs: (data.Workdescrs || "").split("\n").filter((w: string) => w.length > 0),
      })
      message.success("Aktiviteten er blevet opdateret")
      props.onClose()
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Modal destroyOnClose visible={props.visible} onCancel={() => !loading && props.onClose?.()} title={`Ændr aktivitet - ${act.Name}`} footer={false}>
      <Form labelCol={{ span: 8 }} layout="vertical" onFinish={onSubmit}>
        <Form.Item name="Name" label="Navn" rules={[{ required: true, message: "Påkrævet" }]} initialValue={act.Name}>
          <Input disabled={loading} />
        </Form.Item>

        {(proj.Bevilling == "REACTRF-210029" && !act.ProjectManagement) &&
          <Form.Item name="DeminimisEst" label="Estimeret støtte" tooltip="Estimat for støtte per deltagende organisation" initialValue={act.DeminimisEst} rules={[{ required: true, message: "Påkrævet" }]}>
            <NumberInput addonAfter="Kr." min={1} precision={2} disabled={loading} style={{ width: "100%" }} />
          </Form.Item>
        }

        <Form.Item name="Workdescrs" label="Arbejdsbeskrivelser" tooltip="Én beskrivelse per linje" initialValue={act.Workdescrs?.join("\n")}>
          <Input.TextArea style={{ height: 200 }}></Input.TextArea>
        </Form.Item>

        <Row>
          <Button htmlType="submit" type="primary" loading={loading}>Opdater</Button>
        </Row>

      </Form>
    </Modal>
  )
}