import { Alert, Button, Col, message, Modal, Row } from "antd";
import { useState } from "react";
import { actions, useProjects, useUser, useUserOrganisation } from "../../../../utils/store";
import { projectAliasFromActivity } from "../../../../utils/utils";
import SplashLayout from "../../../layouts/SplashLayout";
import UserLayout from "../../../layouts/UserLayout";
import Error from "../../../shared/Error";
import Logo from "../../../shared/Logo";

export default function OrgSignDeMinimisModal() {
  const user = useUser();
  const userOrg = useUserOrganisation();
  const projs = useProjects();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);


  if (!user || !userOrg) return null;

  async function onSend() {
    if (!userOrg) return null

    setLoading(true)
    await actions.organisations.sendDeminimis(userOrg.OrganisationID)
      .finally(() => { setLoading(false) })
    message.success(`Dokumentet er blevet sendt til underskrift til ${userOrg.Tegningsberettiget.Email}`)
    setSent(true)
  }

  async function onRefresh() {
    if (!userOrg) return null

    setLoading(true)
    await actions.organisations.refreshDeminimis(userOrg.OrganisationID)
      .finally(() => { setLoading(false) })
  }

  const activities = userOrg.Activities?.filter(a => !a.DeminimisSigned) || [];

  return (
    <UserLayout>
      <SplashLayout>
        <Modal visible closable={false} footer={null}>
          <Row justify="center" style={{ marginBottom: 20 }}><Logo /></Row>
          <Row justify="center">
            <Alert type="info" description={
              <>
                <Row>
                  Din organisation er tilføjet til ét eller flere projekter.
                  I den forbindelse skal der underskrives en de minimis erklæring.
                </Row>
                <p style={{ marginTop: 20 }}>
                  Det drejer sig om flg. projekter:
                  <ul>
                    {activities.map(oa => {
                      const projAlias = projectAliasFromActivity(oa.ActivityID)
                      const p = projs?.find(p => p.Alias == projAlias)
                      if (!p) return null;
                      const a = p.Activities?.find(a => a.ActivityID == oa.ActivityID)
                      if (!a) return null;

                      return <li><strong>{p.Name} - {a.Name}</strong></li>
                    })}
                  </ul>
                </p>

                <Row>De minimis erklæringen skal underskrives digitalt af den tegningsberettiget:</Row>
                <Row style={{ marginBottom: 20 }} justify="center"><strong>{`${userOrg.Tegningsberettiget.Name} <${userOrg.Tegningsberettiget.Email}>`}</strong></Row>
                <Row justify="center">Når erklæringen er underskrevet skal du trykke "Opdater status"</Row>
                <Row justify="center" style={{ marginTop: 20 }} gutter={10}>
                  {
                    userOrg.Tegningsberettiget.Email === user.Email
                      ?
                      <Col>
                        <Button type="primary" target="_blank" href={userOrg.Deminimis.Signature?.Signatures[0].SignLink}>Underskriv nu</Button>
                      </Col>
                      :
                      <Col>
                        <Button disabled={sent} loading={loading} onClick={onSend} type="primary">Udsend e-mail</Button>
                      </Col>
                  }
                  <Col>
                    <Button onClick={onRefresh} loading={loading}>Opdater status</Button>
                  </Col>
                </Row>
              </>
            } />
          </Row>
        </Modal >
      </SplashLayout>
    </UserLayout>
  )
}