import { Button, Form, Input, message, Modal, Row } from "antd";
import { useState } from "react";
import { actions, useCurProject } from "../../../../utils/store";

type Props = {
  visible: boolean;
  onClose: () => any;
}

export default function ProjectManagerModal(props: Props) {
  const curProject = useCurProject();
  const [loading, setLoading] = useState(false);

  if (!curProject) return null;

  async function onUpdate(data: any) {
    if (!curProject) return

    setLoading(true);
    try {
      await actions.projects.update({
        ProjectID: curProject.ProjectID,
        ProjectManager: { Name: data.Name, Email: data.Email }
      })
      message.success("Projektleder er blevet opdateret")
      props.onClose();
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Modal destroyOnClose title={`Ændr projektleder - ${curProject.Name}`} visible={props.visible} onCancel={() => !loading && props.onClose()} footer={false}>
      <Form labelCol={{ span: 8 }} onFinish={onUpdate}>
        <Form.Item name="Name" label="Fulde navn" initialValue={curProject.ProjectManager.Name} rules={[{ required: true, message: "Påkrævet" }]}>
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item name="Email" label="Email" initialValue={curProject.ProjectManager.Email} rules={[{ required: true, message: "Påkrævet" }]}>
          <Input type="email" disabled={loading} />
        </Form.Item>

        <Row justify="center">
          <Button htmlType="submit" type="primary" loading={loading}>Opdater</Button>
        </Row>
      </Form>
    </Modal>
  )
}