import { Col, Dropdown, Row } from "antd"
import { useState } from "react"
import { BiCaretDown } from "react-icons/bi"
import { Link } from "react-router-dom"

type Props = {
  link?: string
  name?: JSX.Element | string | null
  details?: (JSX.Element | string | null | undefined)[]
  dropdown?: JSX.Element | null
  header?: boolean
}

export default function Name(props: Props) {


  const name = (
    <Col style={{ cursor: props.dropdown ? "pointer" : "unset" }}>
      <Row align="middle" style={{
        fontWeight: props.header ? 700 : 600,
        fontSize: props.header ? "1.2em" : "1em",
        lineHeight: 1.2,
      }}>
        {props.name} {props.dropdown && <BiCaretDown style={{ marginLeft: 2 }} fontSize="1.0em" />}
      </Row>
      {
        props.details?.map((d, i) =>
          <Row key={i} style={{ lineHeight: 1.2, fontSize: "0.85em" }}>{d}</Row>
        )
      }
    </Col>
  )

  if (props.dropdown) {
    return (
      <Dropdown trigger={["click"]} destroyPopupOnHide overlay={props.dropdown}>
        {name}
      </Dropdown>
    )
  }

  return props.link ? <Link to={props.link} style={{ color: "black" }}>{name}</Link> : name
}