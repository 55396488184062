import { Button, Col, Input, Row, Select, Table, TableColumnsType, Tabs, Tag } from "antd"
import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useOrganisations, usePersons, useProjects, useRoles } from "../../../utils/store"
import { hasRole, signatureFailed, signatureMissing, signaturePending, signatureSigned } from "../../../utils/utils";
import Name from "../../shared/Name";
import SignatureStatus from "../../shared/SignatureStatus";

export default function Stamdata() {
  const roles = useRoles();
  const projs = useProjects();
  const orgs = useOrganisations()
  const pers = usePersons()

  if (!orgs || !pers || !projs) return null;


  const orgColumns: TableColumnsType<Organisation> = [
    {
      title: "Navn",
      dataIndex: "Name",
      filterIcon: <BiSearch size={16} />,
      sorter: (a, b) => {
        const orgA = orgs.find(o => o.OrganisationID === a.OrganisationID);
        const orgB = orgs.find(o => o.OrganisationID === b.OrganisationID);

        if (orgA?.Name == null) return 1;
        if (orgB?.Name == null) return -1;
        return orgA.Name.localeCompare(orgB.Name);
      },
      onFilter: (f: any, v) => {
        const org = orgs.find(p => p.OrganisationID === v.OrganisationID);
        const searchStr = `${org?.Name || ""} ${org?.CVR || ""}`;
        return searchStr.toLowerCase().includes(f);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        function onInput(e: any) {
          setSelectedKeys(e.target.value ? [e.target.value.toLowerCase()] : []);
          confirm({ closeDropdown: false });
        }
        return (
          <div style={{ padding: 10 }}>
            <Input placeholder="Søg..." allowClear onChange={onInput} suffix={<BiSearch />} />
          </div>
        )
      },
      render: (v, o) => (
        <Link to={`/organisation/${o.CVR}`} style={{ color: "black" }}>
          <Row style={{ fontWeight: 600, lineHeight: 1.2 }}>{v}</Row>
          <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.CVR}</Row>
        </Link>
      )
    },
    {
      title: "Type",
      dataIndex: "Type",
      sorter: (a, b) => {
        return a.Type.localeCompare(b.Type)
      },
      onFilter: (f, o) => {
        switch (f) {
          case "participant": return o.Type === "participant";
          case "partner": return o.Type === "partner" || o.Type === "vip";
        }
        return false
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        function onStatusClear() {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
        }
        function onStatusChange(status: any) {
          setSelectedKeys(status);
          confirm({ closeDropdown: false });
        }

        return (
          <div style={{ padding: 10 }}>
            <Select showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
              <Select.Option key="participant">Deltager</Select.Option>
              <Select.Option key="partner">Partner</Select.Option>
            </Select>
          </div>
        )
      },
      render: (v, o) => {
        switch (v) {
          case "participant": return <Tag color="blue">Deltager</Tag>
          case "partner":
          case "vip":
            return <Tag color="cyan">Partner</Tag>
          default:
            return "-"
        }
      }
    },
    {
      title: "Deltageraftale",
      sorter: (a, b) => {
        const aSigned = (a.Type == "vip" || signatureSigned(a.Aftale)) ? 1 : 0;
        const bSigned = (b.Type == "vip" || signatureSigned(b.Aftale)) ? 1 : 0;
        return aSigned - bSigned;
      },
      onFilter: (f, o) => {
        if (signatureMissing(o.Aftale) || signaturePending(o.Aftale))
          return f === "missing"
        if (signatureSigned(o.Aftale))
          return f === "signed";
        if (signatureFailed(o.Aftale))
          return f === "error"

        return false
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        function onStatusClear() {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
        }
        function onStatusChange(status: any) {
          setSelectedKeys(status);
          confirm({ closeDropdown: false });
        }

        return (
          <div style={{ padding: 10 }}>
            <Select showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
              <Select.Option key="signed">Underskrevet</Select.Option>
              <Select.Option key="missing">Mangler</Select.Option>
              <Select.Option key="error">Fejl</Select.Option>
            </Select>
          </div>
        )
      },
      render: (v, o) => <SignatureStatus signature={o.Aftale} fake={o.Type == "vip"} />
    },
    {
      title: "De minimis",
      sorter: (a, b) => {
        const aDeminimis = a.Activities?.find(a => !a.DeminimisSigned) ? 0 : 1
        const bDeminimis = b.Activities?.find(a => !a.DeminimisSigned) ? 0 : 1
        return aDeminimis - bDeminimis;
      },
      onFilter: (f, o) => {
        const hasUnsigned = (o.Type == "participant")
          ? o.Activities?.find(a => !a.DeminimisSigned) != null
          : false;

        if (signatureMissing(o.Deminimis.Signature)) {
          if (hasUnsigned) return f === "missing"
          return f === "irrelevant"
        }
        if (signaturePending(o.Deminimis.Signature))
          return f === "missing"
        if (signatureSigned(o.Deminimis.Signature))
          return f === "signed";
        if (signatureFailed(o.Deminimis.Signature))
          return f === "error"

        return false
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        function onStatusClear() {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
        }
        function onStatusChange(status: any) {
          setSelectedKeys(status);
          confirm({ closeDropdown: false });
        }

        return (
          <div style={{ padding: 10 }}>
            <Select showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
              <Select.Option key="signed">Underskrevet</Select.Option>
              <Select.Option key="missing">Mangler</Select.Option>
              <Select.Option key="error">Fejl</Select.Option>
              <Select.Option key="irrelevant">Ikke relevant</Select.Option>
            </Select>
          </div>
        )
      },
      render: (v, o) => {
        if (o.Type !== "participant") return <Tag>Ikke relevant</Tag>
        const hasUnsigned = o.Activities?.find(a => !a.DeminimisSigned) ? true : false;

        if (hasUnsigned || !signatureMissing(o.Deminimis.Signature))
          return <SignatureStatus signature={o.Deminimis.Signature} />

        return <Tag>Ikke relevant</Tag>
      }
    }
  ]
  const persColumns: TableColumnsType<Person> = [
    {
      title: "Navn",
      filterIcon: <BiSearch size={16} />,
      sorter: (a, b) => {
        const persA = pers.find(p => p.PersonID === a.PersonID);
        const persB = pers.find(p => p.PersonID === b.PersonID);

        if (persA?.FullName == null) return 1;
        if (persB?.FullName == null) return -1;
        return persA.FullName.localeCompare(persB.FullName);
      },
      onFilter: (f: any, v) => {
        const p = pers.find(p => p.PersonID === v.PersonID);
        const org = p?.OrganisationID ? orgs.find(o => o.OrganisationID === p.OrganisationID) : undefined;
        const searchStr = `${p?.FullName || ""} ${p?.Email || ""} ${org?.Name || ""} ${org?.CVR || ""}`;
        return searchStr.toLowerCase().includes(f);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        function onInput(e: any) {
          setSelectedKeys(e.target.value ? [e.target.value.toLowerCase()] : []);
          confirm({ closeDropdown: false });
        }
        return (
          <div style={{ padding: 10 }}>
            <Input placeholder="Søg..." allowClear onChange={onInput} suffix={<BiSearch />} />
          </div>
        )
      },
      render: (v, o) => (
        o.State == "ready"
          ?
          <Link to={`/registrering/${o.Email}`} style={{ color: "black" }}>
            <Row style={{ fontWeight: 600, lineHeight: 1.2 }}>{o.FullName}</Row>
            <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Email}</Row>
          </Link>
          :
          <>
            <Row style={{ lineHeight: 1.2 }} align="middle">{`<inviteret>`}</Row>
            <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Email}</Row>
          </>
      )
    },
    {
      title: "Type",
      dataIndex: "Type",
      sorter: (a, b) => {
        const aEmp = a.OrganisationID ? 1 : 0;
        const bEmp = b.OrganisationID ? 1 : 0;
        return aEmp - bEmp;
      },
      onFilter: (f, o) => {
        switch (f) {
          case "employee": return o.OrganisationID ? true : false;
          case "solo": return o.OrganisationID ? false : true;
        }
        return false
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        function onStatusClear() {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
        }
        function onStatusChange(status: any) {
          setSelectedKeys(status);
          confirm({ closeDropdown: false });
        }

        return (
          <div style={{ padding: 10 }}>
            <Select showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
              <Select.Option key="employee">Medarbejder</Select.Option>
              <Select.Option key="solo">Privatperson</Select.Option>
            </Select>
          </div>
        )
      },
      render: (v, o) => (o.OrganisationID ? <Tag color="blue">Medarbejder</Tag> : <Tag color="cyan">Privatperson</Tag>)
    },
    {
      title: "Organisation",
      dataIndex: "OrganisationID",
      sorter: (a, b) => {
        const orgA = orgs.find(o => o.OrganisationID === a.OrganisationID);
        const orgB = orgs.find(o => o.OrganisationID === b.OrganisationID);

        if (orgA?.Name == null) return 1;
        if (orgB?.Name == null) return -1;
        return orgA.Name.localeCompare(orgB.Name);
      },
      render: (v) => {
        if (!v) return null;
        const o = orgs.find(o => o.OrganisationID == v);
        if (!o) return null;

        return <Name link={`/organisation/${o.CVR}`} name={o.Name} details={[`CVR: ${o.CVR}`]} />
      }
    }
  ]

  const projsColumns: TableColumnsType<Project> = [
    {
      title: "Navn",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (v, o) => <Link to={`/projekt/${o.Alias}`} style={{ color: "black", fontWeight: 600 }}>{v}</Link>
    },
    {
      title: "Bevilling",
      dataIndex: "Bevilling",
      sorter: (a, b) => a.Bevilling.localeCompare(b.Bevilling),
      onFilter: (f, o) => {
        switch (f) {
          case "regionalfond": return o.Bevilling == "REACTRF-210029";
          case "socialfond": return o.Bevilling == "REACTSF-210031";
        }
        return false
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        function onStatusClear() {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
        }
        function onStatusChange(status: any) {
          setSelectedKeys(status);
          confirm({ closeDropdown: false });
        }

        return (
          <div style={{ padding: 10 }}>
            <Select showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
              <Select.Option key="regionalfond">Regionalfond</Select.Option>
              <Select.Option key="socialfond">Socialfond</Select.Option>
            </Select>
          </div>
        )
      },
      render: (v) => {
        switch (v) {
          case "REACTRF-210029": return <Tag color="blue">Regionalfond</Tag>
          case "REACTSF-210031": return <Tag color="cyan">Socialfond</Tag>
        }
      }
    },
    {
      title: "Projektleder",
      render: (v, o) => <>
        <Row style={{ fontWeight: 600, lineHeight: 1.2 }}>{o.ProjectManager.Name}</Row>
        <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.ProjectManager.Email}</Row>
      </>
    }
  ]

  if (hasRole(roles, "admin")) {
    orgColumns.push({ title: "OrganisationID", dataIndex: "OrganisationID", })
    orgColumns.push({ title: "State", dataIndex: "State", })
    persColumns.push({ title: "PersonID", dataIndex: "PersonID", })
    persColumns.push({ title: "State", dataIndex: "State", })
    projsColumns.push({ title: "ProjectID", dataIndex: "ProjectID", })
  }

  return (
    <Col flex="0 1 1200px">
      <Tabs>
        <Tabs.TabPane key="orgs" tab="Organisationer" >
          <Table dataSource={orgs} columns={orgColumns} size="small" pagination={false} rowKey="OrganisationID" />
        </Tabs.TabPane>
        <Tabs.TabPane key="pers" tab="Personer" >
          <Table dataSource={pers} columns={persColumns} size="small" pagination={false} rowKey="PersonID" />
        </Tabs.TabPane>
        <Tabs.TabPane key="proj" tab="Projekter" >
          <Table dataSource={projs} columns={projsColumns} size="small" pagination={false} rowKey="ProjectID" />
        </Tabs.TabPane>
      </Tabs>
    </Col>
  )
}
