import { Row, Tag } from "antd";
import { BiPen } from "react-icons/bi";

type Props = {
  reg: Registration
}
export default function RegStatusTag(props: Props) {
  let tag = <Tag color="red">Fejl - {props.reg.State}</Tag>;

  switch (props.reg.State) {
    case "waiting": tag = <Tag>Venter</Tag>; break;
    case "missing": tag = <Tag color="orange">Mangler</Tag>; break;
    case "submitted": tag = <Tag color="green">Indsendt</Tag>; break;
    case "rejected": tag = <Tag color="red">Afvist</Tag>; break;
    case "needs-signing": tag = <Tag color="blue"><BiPen size={14} style={{ marginBottom: -2 }} /> Underskriv</Tag>; break;
    case "accepted": tag = <Tag color="green">Godkendt</Tag>; break;
    case "unlocked": tag = <Tag color="green">Oplåst</Tag>; break;
  }
  return <Row align="middle" justify="space-between">{tag}</Row>
}