import { Alert, Button, Col, Collapse, DatePicker, Form, Input, message, Modal, Row, Select, Tabs, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { actions, useOrganisations, usePersons, useProjects } from "../../../utils/store";
import { rpc, uploadFile } from "../../../utils/utils";
import NumberInput from "../../shared/NumberInput";

export default function Admin() {
  const pers = usePersons();
  const orgs = useOrganisations();
  const projs = useProjects();
  const signInEmail = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const [roleForm] = useForm();

  async function getSignInLink() {
    const email = signInEmail.current.input.value;
    const link = await rpc("admin.SignInAs", { Email: email })
    Modal.info({
      title: "Sign in link",
      content: <a href={link}>{link}</a>
    })
  }

  async function onFirestoreBackup() {
    setLoading(true);
    try {
      const link = await rpc("admin.BackupData")
      Modal.info({ title: "Backup af database er fuldført", })
    }
    finally {
      setLoading(false);
    }
  }

  async function onShowRoles() {
    const { type, id } = roleForm.getFieldsValue();
    if (type == "org") {
      const org = orgs?.find(o => o.OrganisationID == id);
      if (!org) {
        message.error("no such organisation")
        return;
      }
      Modal.info({
        title: `Roller for ${org.Name} (CVR: ${org.CVR})`,
        content: <>
          < h4 style={{ marginTop: 30 }}> Organisation roller:</h4 >
          {org?.Roles?.map(r => <div> {r}</div >) || "ingen"}
        </>
      })
    }
    else if (type == "pers") {
      const p = pers?.find(p => p.PersonID == id);
      let org: Organisation | null = null;
      if (!p) {
        message.error("no such person")
        return;
      }
      if (p.OrganisationID) {
        org = orgs?.find(o => o.OrganisationID == p.OrganisationID) || null;
        if (!org) {
          message.error("no such organisation")
          return;
        }
      }
      Modal.info({
        title: `Roller for ${p.FullName} (${p.Email})`,
        content: <>
          <h4 style={{ marginTop: 30 }}>Person roller:</h4>
          {p.Roles?.map(r => <div>{r}</div>) || "ingen"}
          < h4 style={{ marginTop: 30 }}> Organisation roller{org && ` - ${org.Name} (CVR: ${org.CVR})`}:</h4 >
          {org?.Roles?.map(r => <div> {r}</div >) || "ingen"
          }
        </>
      })
    }
    else {
      message.error("select a type")
    }
  }

  async function onAddRole() {
    const { type, id, role } = roleForm.getFieldsValue();
    if (!role) {
      message.error("no role selected")
      return;
    }
    if (type == "org") {
      const org = orgs?.find(o => o.OrganisationID == id);
      if (!org) {
        message.error("no such organisation")
        return;
      }
      const newRoles = Array.from(new Set([...(org.Roles || []), role]));
      await actions.organisations.setRoles(org.OrganisationID, newRoles || [])
      message.success("roller opdateret");
    }
    else if (type == "pers") {
      const p = pers?.find(p => p.PersonID == id);
      if (!p) {
        message.error("no such person")
        return;
      }
      const newRoles = Array.from(new Set([...(p.Roles || []), role]));
      await actions.persons.setRoles(p.PersonID, newRoles || [])
      message.success("roller opdateret");
    }
    else {
      message.error("select a type")
    }
  }

  async function onRemoveRole() {
    const { type, id, role } = roleForm.getFieldsValue();
    if (!role) {
      message.error("no role selected")
      return;
    }
    if (type == "org") {
      const org = orgs?.find(o => o.OrganisationID == id);
      if (!org) {
        message.error("no such organisation")
        return;
      }
      const newRoles = org.Roles?.filter(r => r !== role);
      await actions.organisations.setRoles(org.OrganisationID, newRoles || [])
      message.success("roller opdateret");
    }
    else if (type == "pers") {
      const p = pers?.find(p => p.PersonID == id);
      if (!p) {
        message.error("no such person")
        return;
      }
      const newRoles = p.Roles?.filter(r => r !== role);
      await actions.persons.setRoles(p.PersonID, newRoles || [])
      message.success("roller opdateret");
    }
    else {
      message.error("select a type")
    }
  }

  async function onManualAftale(values: any) {
    const uploadUrl = await actions.organisations.manualAftale({ OrganisationID: values.OrganisationID, SignedAt: values.SignedAt });
    await uploadFile(uploadUrl, values.File.file.originFileObj);
    message.success("Aftale uploadet");
  }


  async function onManualAlloc(values: any) {
    console.log(values);
    const uploadUrl = await actions.persons.manualAllocation({ PersonID: values.PersonID, SignedAt: values.SignedAt, Start: values.Start, Activities: values.Activities });
    await uploadFile(uploadUrl, values.File.file.originFileObj);
    message.success("Allokering uploadet");
  }

  return (
    <Col flex="0 1 1200px">

      <Tabs>

        <Tabs.TabPane tab="Roller" key="roles">
          <Form layout="vertical" form={roleForm}>
            <Row>
              <Col flex="0 0 150px">
                <Form.Item label="Type" name="type">
                  <Select defaultActiveFirstOption>
                    <Select.Option key="org">Organisation</Select.Option>
                    <Select.Option key="pers">Person</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label="ID" name="id">
                  <Input />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label="Rolle" name="role">
                  <Select showSearch>
                    <Select.Option key="admin">Admin</Select.Option>
                    <Select.Option key="controller">Controller</Select.Option>
                    {projs?.map(p => <Select.Option key={`project-manager-${p.Alias}`}>Projektleder - {p.Name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>

              <Button onClick={onShowRoles}>Vis roller</Button>
              <Button onClick={onAddRole}>Tilføj rolle</Button>
              <Button onClick={onRemoveRole}>Fjern rolle</Button>
            </Row>
          </Form>

        </Tabs.TabPane>

        <Tabs.TabPane tab="Login" key="login">
          <Row align="middle" justify="center">
            <Col flex="0 1 800px" >
              <Row align="middle">
                <Col flex="0 0 100px">Log ind som:</Col>
                <Col flex={1}><Input placeholder="Indtast brugerens email" ref={signInEmail} type="email" /></Col>
                <Col><Button onClick={getSignInLink}>Log ind</Button></Col>
              </Row>
            </Col>
          </Row>


          <Row align="middle" justify="center" style={{ marginTop: 40 }}>
            <Col flex="0 1 800px">
              <Collapse>
                <Collapse.Panel header="Advanceret" key={1}>
                  <Col flex="0 0 100px">JWT:</Col>
                  <Col flex={1}><Input readOnly value={localStorage.getItem("jwt") || ""} /></Col>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row >
        </Tabs.TabPane>

        <Tabs.TabPane tab="Manuelle dokumenter" key="manual-docs">

          <Tabs>
            <Tabs.TabPane tab="Deltageraftale" key="aftale">
              <Form layout="vertical" onFinish={onManualAftale}>
                <Form.Item name="OrganisationID" label="OrganisationsID" rules={[{ required: true }]}><Input /></Form.Item>
                <Form.Item name="SignedAt" label="Dato for underskrift" rules={[{ required: true }]}><DatePicker /></Form.Item>
                <Alert type="warning" description={
                  <>
                    Dokumentet bør indeholde flg. samlet i ét:
                    <ul>
                      <li>EU-partnererklæring</li>
                      <li>SMV-erklæring (hvis relevant)</li>
                    </ul>
                  </>
                } />
                <Form.Item name="File" label="Fil" rules={[{ required: true }]}>
                  <Upload accept=".pdf" maxCount={1}>
                    <Button>Vælg fil</Button>
                  </Upload>
                </Form.Item>
                <Button htmlType="submit">Upload manuel deltageraftale</Button>
              </Form>

            </Tabs.TabPane>
            <Tabs.TabPane tab="Allokering" key="allokering">

              <Form layout="vertical" onFinish={onManualAlloc}>
                <Form.Item name="PersonID" label="PersonID" rules={[{ required: true }]}><Input /></Form.Item>
                <Form.Item name="SignedAt" label="Dato for underskrift" rules={[{ required: true }]}><DatePicker /></Form.Item>
                <Form.Item name="Start" label="Startdato" rules={[{ required: true }]}><DatePicker /></Form.Item>
                <hr />
                <h3>Allokeringer:</h3>
                <Form.List name="Activities">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Row key={field.key} align="middle">
                            <Col flex="0 0 300px">
                              <Form.Item
                                {...field}
                                name={[field.name, "ActivityID"]}
                                rules={[{ required: true }]}
                                label="Aktivitet"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...field}
                                name={[field.name, "Alloc"]}
                                rules={[{ required: true }]}
                                label="Allokering %"
                              >
                                <NumberInput precision={2} />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Button danger type="link" onClick={() => remove(index)}><BiTrash /></Button>
                            </Col>
                          </Row>
                        ))}
                        <Button onClick={() => add()}>Tilføj</Button>
                      </div>
                    )
                  }}
                </Form.List>
                <hr />
                <Alert type="warning" description={
                  <>Dokumentet bør indeholde allokering for begge bevillinger, samlet i ét</>
                } />
                <Form.Item name="File" label="Fil" rules={[{ required: true }]}>
                  <Upload accept=".pdf" maxCount={1}>
                    <Button>Vælg fil</Button>
                  </Upload>
                </Form.Item>
                <Button htmlType="submit">Upload manuel allokering</Button>
              </Form>

            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Backup" key="backup">
          <Row>
            <Col>

              <ol>
                <li>
                  <Row gutter={10} align="middle">
                    <Col>Klik her for at lave backup af databasen</Col>
                    <Col><Button loading={loading} onClick={onFirestoreBackup} size="small" type="primary">Backup database</Button></Col>
                  </Row>
                </li>
                <li style={{ marginTop: 20 }}>
                  Brug <a href="https://cloud.google.com/storage/docs/gsutil_install" target="_blank"><code>gsutil</code></a> til at lave backup af filer ved at køre kommandoen:
                  <div>
                    <code style={{ padding: "5px 20px", background: "black", color: "white", borderRadius: 4 }}>gsutil -m cp -r "gs://fyrtaarn-go.appspot.com/prod" .</code>
                  </div>
                </li>
                <li style={{ marginTop: 20 }}>
                  Omdøb mappen til dagsdato og upload i Box <a href="https://cleancluster.app.box.com/folder/161386017262" target="_blank">her</a>
                </li>
              </ol>





            </Col>
          </Row>
        </Tabs.TabPane>

      </Tabs>
    </Col >
  )
}