import { Button, Col, Collapse, Descriptions, Row, Table, TableColumnsType, Tabs, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { BiEdit, BiHide, BiInfoCircle } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { actions, useCurProject, useOrganisations, usePersons, useProjects, useRoles } from "../../../utils/store";
import { formatMonth, hasRole, monthToMoment, projectAliasFromActivity, projectEnd, projectStart } from "../../../utils/utils";
import LogPane from "../../shared/LogPane";
import Name from "../../shared/Name";
import CreateActivityModal from "./components/CreateActivityModal";
import EditActivityModal from "./components/EditActivityModal";
import InviteModal from "./components/InviteModal";
import ProjectHeader from "./components/ProjectHeader";
import ProjectManagerModal from "./components/ProjectManagerModal";

export default function ProjectAlias() {
  const roles = useRoles();
  const projs = useProjects();
  const curProj = useCurProject();
  const orgs = useOrganisations();
  const pers = usePersons();

  const [showCreateActivity, setShowCreateActivity] = useState(false);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [showEditPM, setShowEditPM] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [inviteType, setInviteType] = useState("");

  const params = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    if (!projs) return;

    const newProj = projs.find(p => p.Alias === params.alias);
    if (newProj) actions.selectProject(newProj.ProjectID);
    else navigate(`/projekt`);

  }, [params.alias, projs])

  function onProjSelect(proj: Project | null) {
    if (!proj) navigate(`/projekt`);
    else navigate(`/projekt/${proj.Alias}`)
  }

  const actColumns: TableColumnsType<Activity> = [
    {
      title: "Aktivitet",
      dataIndex: "Name",
    },
    {
      title: "Periode",
      render: (_, a) => {
        const start = formatMonth(a.Start || projectStart);
        const end = formatMonth(a.End || projectEnd);
        return `${start} - ${end}`;
      },
    },
  ]

  if (!curProj || !orgs || !pers) return null;

  if (curProj.Bevilling == "REACTRF-210029") {
    actColumns.push({
      title: <Tooltip title="Estimat for støtte per deltagende organisation"><Row align="middle" gutter={5}>Estimeret støtte < BiInfoCircle style={{ marginLeft: 5 }} /></Row></Tooltip>,
      render: (_, a) => {
        if (a.ProjectManagement) return "-"
        return `${a.DeminimisEst} kr. / organisation`
      }
    })
  }

  if (hasRole(roles, `project-manager-${curProj.Alias}`)) {
    actColumns.push({
      render: (_, a) => {
        return <Button type="link" size="small"
          onClick={() => {
            actions.selectActivity(a.ActivityID);
            setShowEditActivity(true)
          }}
        ><BiEdit /></Button>
      }
    })
  }

  const partnerColumns: TableColumnsType<Organisation> = [
    {
      title: "Navn",
      render: (_, o) => <Name link={`/organisation/${o.CVR}`} name={o.Name} details={["CVR: " + o.CVR]} />
    },
    {
      title: "Aktiviteter",
      render: (_, o) => {
        if (!curProj) return null;

        let out: string[] = [];

        for (const a of (o.Activities || [])) {
          const projAlias = projectAliasFromActivity(a.ActivityID);
          if (projAlias != curProj.Alias) continue;

          const act = curProj.Activities?.find(a2 => a2.ActivityID == a.ActivityID);
          if (!act) out.push(a.ActivityID);
          else out.push(act.Name);
        }

        return out.map(a => <Tag color="blue" key={a}>{a}</Tag>)
      }
    }
  ];
  const regColumns: TableColumnsType<Organisation> = [
    {
      title: "Navn",
      render: (_, o) => <Name link={`/organisation/${o.CVR}`} name={o.Name} details={["CVR: " + o.CVR]} />
    },
    {
      title: "Aktiviteter",
      render: (_, o) => {
        if (!curProj) return null;

        let out: string[] = [];

        for (const a of (o.Activities || [])) {
          const projAlias = projectAliasFromActivity(a.ActivityID);
          if (projAlias != curProj.Alias) continue;

          const act = curProj.Activities?.find(a2 => a2.ActivityID == a.ActivityID);
          if (!act) out.push(a.ActivityID);
          else out.push(act.Name);
        }

        return out.map(a => <Tag color="blue" key={a}>{a}</Tag>)
      }
    }
  ];
  const socColumns: TableColumnsType<Organisation | Person> = [
    {
      title: "Navn",
      render: (_, o) => {
        const org = o as Organisation;
        const pers = o as Person;

        if (org.CVR) {
          return <Name link={`/organisation/${org.CVR}`} name={org.Name} details={["CVR: " + org.CVR]} />
        }
        else {
          return pers.FullName
        }
      }
    },
    {
      title: "Aktiviteter",
      render: (_, o) => {
        if (!curProj) return null;

        let out: string[] = [];

        for (const a of (o.Activities || [])) {
          const projAlias = projectAliasFromActivity(a.ActivityID);
          if (projAlias != curProj.Alias) continue;

          const act = curProj.Activities?.find(a2 => a2.ActivityID == a.ActivityID);
          if (!act) out.push(a.ActivityID);
          else out.push(act.Name);
        }

        return out.map(a => <Tag color="blue" key={a}>{a}</Tag>)
      }
    }
  ];

  if (hasRole(roles, "admin")) {
    regColumns.push({ title: "OrganisationID", dataIndex: "OrganisationID" })
    socColumns.push({ title: "PersonID", dataIndex: "PersonID" })
    partnerColumns.push({ title: "OrganisationID", dataIndex: "OrganisationID" })
    actColumns.push({ title: "ActivityID", dataIndex: "ActivityID", })
  }


  let partnerData: Organisation[] = [];
  var regParticipantData: Organisation[] = []
  var socParticipantData: Person[] = []

  for (const o of orgs) {
    for (const a of (o.Activities || [])) {
      const projAlias = projectAliasFromActivity(a.ActivityID);
      if (projAlias != curProj.Alias) continue;

      const act = curProj.Activities?.find(a2 => a2.ActivityID == a.ActivityID);
      if (!act) continue;

      if (act.ProjectManagement) {
        const existing = partnerData.find(o2 => o2.OrganisationID == o.OrganisationID);
        if (!existing) partnerData.push(o);
        continue;
      }

      const existing = regParticipantData.find(o2 => o2.OrganisationID == o.OrganisationID);
      if (!existing)
        regParticipantData.push(o);
    }
  }

  if (curProj.Bevilling != "REACTRF-210029")
    socParticipantData = pers.filter(p => p.Activities?.find(a => projectAliasFromActivity(a.ActivityID) == curProj.Alias) != null)


  return (
    <Col flex="0 1 1200px">
      <Row>
        <ProjectHeader onProjSelect={onProjSelect} />
      </Row>
      <Row>
        <Col flex={1} >
          <Collapse style={{ fontSize: "0.75rem", marginTop: -35, pointerEvents: "none" }} ghost expandIconPosition="right" >
            <Collapse.Panel header={<Col flex={1}><Row justify="end" align="middle"><span style={{ pointerEvents: "all" }}>Detaljer</span></Row></Col>} key={1}>
              <Descriptions bordered size="small" style={{ pointerEvents: "all" }}>
                <Descriptions.Item label="Projektleder">
                  <>
                    <Row style={{ fontWeight: 600, lineHeight: 1.2 }} align="middle">
                      {curProj.ProjectManager.Name}
                      {
                        (hasRole(roles, `project-manager-${curProj.Alias}`)) &&
                        <Button type="link" size="small" style={{ height: 18 }} onClick={() => setShowEditPM(true)}><BiEdit /></Button>
                      }
                    </Row>
                    <Row style={{ fontSize: "0.8em", lineHeight: 1.2 }}>{curProj.ProjectManager.Email}</Row>
                  </>
                </Descriptions.Item>
                <Descriptions.Item label="Bevilling">
                  <>
                    <Row style={{ lineHeight: 1.2 }}>{curProj.Bevilling == "REACTRF-210029" ? "Regionalfond" : "Socialfond"}</Row>
                    <Row style={{ fontSize: "0.8em", lineHeight: 1.2 }}>{curProj.Bevilling}</Row>
                  </>
                </Descriptions.Item>
                <Descriptions.Item label="Projekt ID">
                  <>
                    <Row style={{ lineHeight: 1.2 }}>{curProj.Alias}</Row>
                    <Row style={{ fontSize: "0.8em", lineHeight: 1.2 }}>{curProj.ProjectID}</Row>
                  </>
                </Descriptions.Item>
                <Descriptions.Item label="Historik"><Button size="small" onClick={() => setShowLog(true)}>Se historik</Button></Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
        </Col >
      </Row >
      <Row>
        <Col flex={1}>
          <Tabs>
            <Tabs.TabPane tab="Deltagere" key="deltagere">
              <Row>
                <Col flex={1}>
                  {
                    curProj.Bevilling == "REACTRF-210029"
                      ? <Table size="small" columns={regColumns} dataSource={regParticipantData} pagination={false} rowKey="OrganisationID" />
                      : <Table size="small" columns={socColumns} dataSource={socParticipantData} pagination={false} rowKey="PersonID" />
                  }
                </Col>
              </Row>
              {
                (hasRole(roles, `project-manager-${curProj.Alias}`)) &&
                <Row justify="center" style={{ marginTop: 20 }}>
                  <Button onClick={() => {
                    if (curProj.Bevilling == "REACTRF-210029") setInviteType("participant");
                    else setInviteType("person");
                    setShowInvite(true)
                  }}>Inviter deltager til aktiviteter</Button>
                </Row>
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Partnere" key="partnere">
              <Row>
                <Col flex={1}>
                  <Table size="small" columns={partnerColumns} dataSource={partnerData} pagination={false} rowKey="OrganisationID" />
                </Col>
              </Row>
              {
                (hasRole(roles, `project-manager-${curProj.Alias}`)) &&
                <Row justify="center" style={{ marginTop: 20 }}>
                  <Button onClick={() => { setInviteType("partner"); setShowInvite(true) }}>Inviter partner til aktiviteter</Button>
                </Row>
              }
            </Tabs.TabPane>

            <Tabs.TabPane tab="Aktiviteter" key="aktiviteter">
              <Row>
                <Col flex={1}>
                  <Table size="small" columns={actColumns} dataSource={curProj.Activities || []} pagination={false} rowKey="ActivityID" />
                </Col>
              </Row>
              {
                (hasRole(roles, `project-manager-${curProj.Alias}`)) &&
                <Row justify="center" style={{ marginTop: 20 }}>
                  <Button onClick={() => setShowCreateActivity(true)}>Opret aktivitet</Button>
                </Row>
              }
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

      <CreateActivityModal visible={showCreateActivity} onClose={() => setShowCreateActivity(false)} />
      <EditActivityModal visible={showEditActivity} onClose={() => setShowEditActivity(false)} />
      <ProjectManagerModal visible={showEditPM} onClose={() => setShowEditPM(false)} />
      <InviteModal key={inviteType} visible={showInvite} onClose={() => setShowInvite(false)} type={inviteType as any} />

      <LogPane log={curProj.Log} visible={showLog} onClose={() => setShowLog(false)} />
    </Col>
  )
}