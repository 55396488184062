import { Button, Col, Divider, Dropdown, Layout, Menu, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    BiBuildings,
    BiChalkboard,
    BiChart,
    BiCheckShield,
    BiCloudUpload,
    BiCodeBlock,
    BiData,
    BiHelpCircle,
    BiMenu,
    BiSpreadsheet
} from "react-icons/bi";
import { useOrganisations, useRoles, useUser } from "../../utils/store";
import { hasRole, useWindowWidth } from "../../utils/utils";
import Logo from "../shared/Logo";

export default function MenuLayout({ children }: any) {
    const roles = useRoles();
    const location = useLocation();
    const [page, setPage] = useState("none");
    const width = useWindowWidth();

    const user = useUser();
    const orgs = useOrganisations();

    useEffect(() => { setPage(location.pathname.split("/")[1]); }, [location.pathname])

    if (!user || !orgs) return null;

    function makeMenu(theme: "dark" | "light", faq = false) {
        if (!user || !orgs) return <></>;

        return (
            <Menu key={page} mode="vertical" theme={theme} defaultSelectedKeys={[page]} activeKey={page} triggerSubMenuAction="click">
                {hasRole(roles, "admin") &&
                    <Menu.Item key="admin">
                        <Link to="/admin">
                            <Row align="middle"><BiCodeBlock style={{ marginRight: 5 }} /> Admin</Row>
                        </Link>
                    </Menu.Item>
                }
                {hasRole(roles, "program-lead") &&
                    <Menu.Item key="program">
                        <Link to="/program">
                            <Row align="middle"><BiChart style={{ marginRight: 5 }} /> Programoverblik</Row>
                        </Link>
                    </Menu.Item>
                }
                {hasRole(roles, "controller") &&
                    <Menu.Item key="controlling">
                        <Link to="/controlling">
                            <Row align="middle"><BiCheckShield style={{ marginRight: 5 }} />Controlling</Row>
                        </Link>
                    </Menu.Item>
                }
                {hasRole(roles, "project-manager") &&
                    <>
                        <Menu.Item key="stamdata">
                            <Link to="/stamdata">
                                <Row align="middle"><BiData style={{ marginRight: 5 }} /> Stamdata</Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="projekt">
                            <Link to="/projekt">
                                <Row align="middle"><BiChalkboard style={{ marginRight: 5 }} /> Projektoverblik</Row>
                            </Link>
                        </Menu.Item>
                    </>
                }

                {orgs.length > 0 &&
                    <Menu.Item key="organisation">
                        <Link to="/organisation">
                            <Row align="middle"><BiBuildings style={{ marginRight: 5 }} /> Organisation</Row>
                        </Link>
                    </Menu.Item>
                }
                <Menu.Item key="registrering">
                    <Link to={`/registrering/${user.Email}`}>
                        <Row align="middle" ><BiSpreadsheet style={{ marginRight: 5 }} /> Registrering</Row>
                    </Link>
                </Menu.Item>
                {faq &&
                    <Menu.Item key="faq">
                        <a href={`https://lighthousewatertech.dk/faq-proxsy/`} target="_blank">
                            <Row align="middle" ><BiHelpCircle style={{ marginRight: 5 }} /> FAQ</Row>
                        </a>
                    </Menu.Item>
                }
                {
                    user.Email =="nivin.joseph@phases.io" &&
                    <Menu.Item key="importexcel">
                        <Link to={`/importexcel`}>
                            <Row align="middle"><BiCloudUpload style={{marginRight: 5}}/>Import File</Row>
                        </Link>
                    </Menu.Item>
                }
            </Menu >
        );
    }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            {width < 1200 ?
                <div style={{ height: 90 }}>
                    <Row style={{ top: 0, left: 0, right: 0, padding: 10, background: "white", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", position: "fixed", zIndex: 99 }} align="top" justify="space-between">
                        <Col flex="1">
                            <Row align="middle">
                                <Dropdown overlay={makeMenu("light", true)} trigger={['click']} destroyPopupOnHide>
                                    <BiMenu size={38} style={{ cursor: "pointer" }} />
                                </Dropdown>
                            </Row>
                        </Col>
                        <Col flex="4">
                            <Row justify="center" align="middle">
                                <Link to="/"><span style={{ cursor: "pointer" }}><Logo /></span></Link>
                            </Row>
                        </Col>
                        <Col flex="1"></Col>
                    </Row>
                </div>
                :
                <Layout.Sider>
                    <Row>
                        <Col flex={1}>
                            <Row justify="center" style={{ paddingTop: 10, opacity: 0.95, marginBottom: 30 }}>
                                <Link to="/"><span style={{ cursor: "pointer" }}><Logo light size="medium" /></span></Link>
                            </Row>
                            {makeMenu("dark")}

                        </Col>
                        <Tooltip title="Her kan du finde hjælp til brug af systemet" placement="right">
                            <div style={{ flex: "1", display: "flex", justifyContent: "center", alignItems: "flex-end", marginTop: 40, textAlign: "center" }}>
                                <Button target="_blank" href="https://lighthousewatertech.dk/faq-proxsy/" type="link" style={{ color: "white", opacity: 0.7 }}>
                                    <Row>
                                        <BiHelpCircle size={32} />
                                    </Row>
                                    <Row justify="center">FAQ</Row>
                                </Button>
                            </div>
                        </Tooltip>
                    </Row>
                </Layout.Sider>
            }
            <Layout style={{ background: "white" }}>
                <Layout.Content style={{ padding: 20 }}>
                    <Row justify="center">
                        {children}
                    </Row>
                </Layout.Content>
            </Layout>

            <div style={{ position: "fixed", right: 10, bottom: 5, opacity: 0.4, fontSize: 12 }}>
                Logo af <strong><a style={{ color: "black" }} target="_blank" href="https://www.flaticon.com/free-icons/lighthouse">Freepik - Flaticon</a></strong>
            </div>
        </Layout>
    )
}