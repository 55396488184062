import { Modal, Form, Input, Button, Row, DatePicker, Alert, message, Divider, Col, Switch } from "antd";
import { useState } from "react";
import { actions, useCurOrganisation } from "../../../../utils/store";
import { momentToMonth, monthToMoment, projectEnd, projectStart } from "../../../../utils/utils";

type Props = {
  visible?: boolean
  onClose: () => any
}
export default function EmployeeInviteModal(props: Props) {
  const curOrg = useCurOrganisation();
  const [loading, setLoading] = useState(false);
  const [hasGodkender, setHasGodkender] = useState(true);
  if (!curOrg) return null;

  async function onSubmit(values: any) {
    if (!curOrg) return
    if (values.end && values.start && values.end.isBefore(values.start)) {
      message.error("Slutmåned kan ikke være før startmåned");
      return;
    }

    const email = values.email;
    const start: moment.Moment | null = values.start;
    const end: moment.Moment | null = values.end;

    setLoading(true);
    try {
      const pers = await actions.organisations.inviteEmployee({
        OrganisationID: curOrg.OrganisationID,
        Email: email,
        Start: start ? momentToMonth(start) : null,
        End: end ? momentToMonth(end) : null,
      })
      await actions.persons.updateGodkender(pers.PersonID, values.godkender)

      message.success(`${email} er blevet inviteret`);
      props.onClose();
    }
    finally {
      setLoading(false);
    }
  }

  if (!curOrg) return null;

  const start = monthToMoment(curOrg.Start || projectStart);
  const end = monthToMoment(curOrg.End || projectEnd);

  return (
    <Modal destroyOnClose visible={props.visible} title="Tilføj medarbejder" onCancel={props.onClose} footer={null}>
      <Alert type="info" message="Medarbejderen vil modtage en invitaion per mail" style={{ marginBottom: 20 }} />
      <Form labelCol={{ span: 8 }} onFinish={v => onSubmit(v)}>
        <Form.Item name="email" label="E-mail" rules={[{ required: true, message: "Påkrævet" }]}>
          <Input disabled={loading} type="email" placeholder="Medarbejderens arbejdsmail" />
        </Form.Item>
        <Form.Item name="start" label="Evt. startmåned">
          <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMMM YYYY"
            placeholder={start.format("MMMM YYYY")}
            disabledDate={v => v.isBefore(start) || v.clone().date(0).isAfter(end)}
          />
        </Form.Item>
        <Form.Item name="end" label="Evt. slutmåned">
          <DatePicker disabled={loading} picker="month" style={{ width: "100%" }} format="MMMM YYYY" placeholder={end.format("MMMM YYYY")}
            disabledDate={v => v.isBefore(start) || v.clone().date(0).isAfter(end)}
          />
        </Form.Item>
        <Divider>Godkender</Divider>
        <Alert message="Personen som angives her skal andengodkende medarbejderens timeregistreringer" style={{ marginBottom: 20 }} />

        <Row align="middle" justify="space-between" style={{ marginBottom: 20 }} gutter={10}>
          <Col flex="80%">Er der nogen i organisationen som kan være andengodkender?</Col>
          <Col><Switch checkedChildren="Ja" unCheckedChildren="Nej" checked={hasGodkender} onChange={v => setHasGodkender(v)} /></Col>
        </Row>

        {hasGodkender &&
          <>
            <Form.Item name={["godkender", "Name"]} label="Godkenders navn" rules={[{ required: true, message: "Påkrævet" }]}>
              <Input disabled={loading} placeholder="Godkenderens fulde navn" />
            </Form.Item>
            <Form.Item name={["godkender", "Email"]} label="Godkenders e-mail" rules={[{ required: true, message: "Påkrævet" }]}>
              <Input disabled={loading} type="email" placeholder="Godkenderens e-mail" />
            </Form.Item>
          </>
        }

        <Row justify="center">
          <Button htmlType="submit" type="primary" loading={loading}>Inviter</Button>
        </Row>
      </Form>
    </Modal>
  )
}