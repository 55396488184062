import { Alert, Button, Checkbox, Col, Form, Input, message, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { actions, useCurPerson } from "../../../../utils/store";

type Props = {
    visible: boolean
    force?: boolean
    onClose?: () => any
}

export default function EmployeeGodkenderModal(props: Props) {
    const pers = useCurPerson();
    const [loading, setLoading] = useState(false);
    const [hasGodkender, setHasGodkender] = useState(true);

    useEffect(() => {
        if (!pers) return
        if (pers.State != "needs-godkender")
            setHasGodkender(pers.Godkender != null)

    }, [pers])
    if (!pers) return null;

    async function onSubmit(values: any) {
        if (!pers) return

        setLoading(true);

        try {
            await actions.persons.updateGodkender(pers.PersonID, values.godkender)
            message.success("Medarbejderen er blevet opdateret")
            props.onClose?.()
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Modal destroyOnClose title={`Ændr godkender for medarbejder -  ${pers.FullName || pers.Email}`} visible={props.visible} closable={!props.force} footer={null} onCancel={() => props.onClose?.()}>
            <Alert message="Angiv hvem der skal andengodkende timeregistreringer for medarbejderen. I fald der ikke er nogen til at andengodkende, er det projektlederen der tager opgaven." style={{ marginBottom: 20 }} />

            <Row align="middle" justify="space-between" style={{ marginBottom: 20 }} gutter={10}>
                <Col flex="80%">Er der nogen i organisationen som kan være andengodkender?</Col>
                <Col><Switch checkedChildren="Ja" unCheckedChildren="Nej" checked={hasGodkender} onChange={v => setHasGodkender(v)} /></Col>
            </Row>

            <Form labelCol={{ span: 8 }} onFinish={onSubmit}>

                {
                    hasGodkender &&
                    <>
                        <Form.Item name={["godkender", "Name"]} label="Godkenders navn" rules={[{ required: true, message: "Påkrævet" }]} initialValue={pers.Godkender?.Name}>
                            <Input placeholder="Indtast fulde navn" />
                        </Form.Item>
                        <Form.Item name={["godkender", "Email"]} label="Godkenders e-mail" rules={[{ required: true, message: "Påkrævet" }]} initialValue={pers.Godkender?.Email}>
                            <Input type="email" placeholder="Indtast e-mail" />
                        </Form.Item>
                    </>
                }
                <Row justify="center">
                    <Button type="primary" htmlType="submit" loading={loading}>Opdater</Button>
                </Row>
            </Form>
        </Modal>
    )
}