import { Button, Modal, Row, Form, Input, Result, message } from "antd";
import { useState } from "react";
import { actions } from "../../../../utils/store";
import SplashLayout from "../../../layouts/SplashLayout";
import Logo from "../../../shared/Logo";

export default function LoginModal() {
  const [state, setState] = useState<"login" | "loading" | "sent">("login");

  async function onLogin(e: any) {
    setState("loading");

    actions.user.initiateLogin(e.email)
      .then(r => {
        message.success(`E-mail blev afsendt til ${e.email}`);
        setState("sent");
      })
      .catch(e => { setState("login") });
  }

  return (
    <SplashLayout>
      <Modal visible closable={false} footer={null} centered>
        {
          (state == "login" || state == "loading") && <>
            <Row justify="center" style={{ marginBottom: 20 }}><Logo size="large" /></Row>
            <Form onFinish={onLogin}>
              <Form.Item name="email" rules={[{ required: true, message: "E-mail skal indtastes" }]}>
                <Input type="email" placeholder="Indtast din e-mail" />
              </Form.Item>
              <Row justify="center">
                <Button htmlType="submit" loading={state == "loading"} type="primary">Log ind</Button>
              </Row>
            </Form>
          </>
        }
        {
          state == "sent" && <Result status="success" title="Tjek din e-mail" subTitle={<>Vi har sendt en e-mail med et login-link<br />Du kan lukke dette vindue</>} />
        }
      </Modal>
    </SplashLayout>
  )
}