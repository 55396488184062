import { InputNumber, InputNumberProps } from "antd";
import { FocusEvent, useState } from "react";

export default function NumberInput(props: InputNumberProps<number>) {

  // Hold the value that is currenly in the input field, but has not been committed yet
  const [tmp, setTmp] = useState<number>(props.value || NaN);
  // Keep track of whether the user is currently editing the input field
  const [isTyping, setIsTyping] = useState(false);

  // Parse the localized input into a number
  function parse(input?: string): number {
    if (!input) {
      // @ts-ignore
      return null
    }

    // Use the locale-specific decimal separator
    input = input.replaceAll(".", "");
    input = input.replaceAll(",", ".");
    let val = parseFloat(input);

    // Round to the specified precision
    const roundFactor = 10 ** (props.precision || 0);
    val = Math.round(val * roundFactor) / roundFactor

    // Limit the value to the specified range
    if (!isNaN(val)) {
      if (props.min != null && val < props.min) {
        val = props.min;
      }
      if (props.max != null && val > props.max) {
        val = props.max;
      }
    }

    return val;
  }

  // Format the number into a localized string
  function format(value: any, info: any): string {

    // Enable clearing the input field
    if (isTyping && info.input == "") return ""
    // Handle missing value
    if (isNaN(tmp)) return "";

    // Make sure the value respects the precision
    const roundFactor = 10 ** (props.precision || 0);
    const val = Math.round(tmp * roundFactor) / roundFactor

    // Format the value according to the locale
    const str = val.toLocaleString();
    let [int, frac] = str.split(",");
    let result = int;
    if (props.precision) {
      // Pad the fraction with zeros according to precision
      result += "," + (frac || "").padEnd(props.precision, "0");
    }

    return result;
  }

  // Handle changes to the number-value within input
  function onChange(value: number) {
    setTmp(value)
    setIsTyping(true)
  }

  // Handle changes to the text-value within the input
  function onInput(value: string) {
    props.onInput?.(value)

    // Handle corner case where user cannot clear field, if value = 0
    if (value == "" && tmp == 0) setTmp(NaN)
  }

  // Commit the value to the input field when focus is lost
  function onBlur(e: FocusEvent<HTMLInputElement>) {
    // @ts-ignore
    props.onChange?.(isNaN(tmp) ? null : tmp)
    props.onBlur?.(e)
    setIsTyping(false)
  }

  // Handle stepping the value up or down
  function onStep(value: number) {
    props.onChange?.(value)
    setIsTyping(false)
  }

  return (
    <InputNumber {...props}
      parser={parse}
      formatter={format}
      value={props.value || NaN}
      onChange={onChange}
      onInput={onInput}
      onStep={onStep}
      onBlur={onBlur}
    />
  )
}