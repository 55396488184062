import { Button, Col, Divider, Form, Input, Row, Select, Table, Tabs, Tag , InputNumber,message} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { BiSearch, BiXCircle } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { actions, useCurMonth, useCurPerson, useCurPersonOrganisation, useCurPersonRegistrations, usePersons, useProjects } from "../../../utils/store";
import { formatMonthShort, getPersonAlloc, monthToMoment, projectAliasFromActivity } from "../../../utils/utils";
import Log from "../../shared/Log";
import Name from "../../shared/Name";
import NumberInput from "../../shared/NumberInput";
import PDFViewer from "../../shared/PDFViewer";
import PersonHeader from "../registrering/components/PersonHeader";
import StatusAlert from "../registrering/components/RegStatusAlert";

export default function ControllingRegistreringEmailMonth() {
  const projs = useProjects();
  const persons = usePersons();
  const curPerson = useCurPerson();
  const curPersonOrg = useCurPersonOrganisation();
  const curMonth = useCurMonth();
  const curPersonRegs = useCurPersonRegistrations();

  const [reg, setReg] = useState<Registration | null>(null);
  const [loading, setLoading] = useState(false);
  const [fjernLoading, setFjernLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!persons) return

    const [yearStr, monthStr] = (params.month as string).split("-");
    const month: Month = { Year: parseInt(yearStr), Month: parseInt(monthStr) };

    actions.selectMonth(month);

    const newPerson = persons.find(p => p.Email === params.email);
    if (!newPerson) {
      navigate(`/controlling/registrering`);
      return
    }

    actions.selectPerson(newPerson.PersonID);

  }, [params.email, params.month, persons]);

  /* BOF When Change Year,Update Alloc data column */
  useEffect(() => {
    if (hasChanged) {
      setHasChanged(false);
      actions.registrations.get();
    }
  }, [curMonth, curPerson, hasChanged]);  
  
  useEffect(() => {
      return () => { actions.registrations.get() }
  }, [])
  /* EOF When Change Year,Update Alloc data column */

  useEffect(() => {
    if (!curMonth || !curPerson || !curPersonRegs) return;

    let reg = curPersonRegs.find(r => r.Month.Year === curMonth.Year && r.Month.Month === curMonth.Month) || null;
    if (!reg || reg.State === "missing") {
      navigate(`/controlling/registrering`)
      return
    }
    /* BOF Showing historical data when creating a new registration */
    let previousMonth = curMonth.Month - 1;
    let previousYear = curMonth.Year;
    if(previousMonth==0){
      previousMonth = 13 - 1;
      previousYear = curMonth.Year - 1;
    }
    let previousReg = curPersonRegs.find(r => r.Month.Year === previousYear && r.Month.Month === previousMonth) || null;
    // console.log("previousReg",previousReg)
    if (previousReg && previousReg.State === "accepted") {
      if (reg.AM === null) {
        reg.AM = previousReg.AM;
      }
      if (reg.ATP === null) {
        reg.ATP = previousReg.ATP;
      }
      if (reg.Pension === null) {
        reg.Pension = previousReg.Pension;
      }
      if (reg.Ferie === null) {
        reg.Ferie = previousReg.Ferie;
      }
    }
    /* EOF Showing historical data when creating a new registration */
    setReg(reg);   

  }, [curMonth, curPersonRegs]);

  // BOF 573 - Add or Update Alloc field on OnLoad the registration 
  useEffect(() => {
    /**
     * return if curPerson | curMonth | curPersonRegs | reg is not set
     */
    if (!curPerson || !curMonth || !curPersonRegs || !reg) return;
    try {
        /**
         *  Add Alloc field in activities field in registration table 
         */
        const addAllocFields = async () => {
            if (!reg) return;
            if (reg.Activities) {
              /**
               *  Check if Alloc field is exists in Activities field in registrations
               *  Default , IsExistReg = false after update IsExistReg = true
               */
                const isAllocExist = reg.Activities.some(
                    (activity) => "Alloc" in activity && (activity.IsExistReg === false)
                );
                var newArr: string | any[] = [];
                /**
                 * Check current users having Allocations field and values
                 */
                const curpersonAlloc = curPerson.Allocations ? curPerson.Allocations.slice(-1) : null;
                if (isAllocExist && curpersonAlloc != null) {
                  /**
                   * Iterate registration activities
                   */
                    for (let [key, value] of Object.entries(reg.Activities)) {
                        if (value.Alloc == 0) {
                            var rAllocNew = 0;
                            /**
                             * Fetch current person Alloc values
                             */
                            const socParticipantData = curpersonAlloc.filter(
                                (p) =>
                                p.Activities !== undefined &&
                                p.Activities?.find(
                                    (a) => a.ActivityID === value.ActivityID && a.Alloc !== 0
                                ) !== undefined
                            );
                            /**
                             * Set the registration activities Alloc value from current person Alloc values
                             */
                            if (socParticipantData.length > 0 && socParticipantData[0].Activities != null) 
                            {
                                if (socParticipantData[0].Activities.length > 0) {
                                    socParticipantData[0].Activities.forEach((act) => {
                                        if (act.ActivityID === value.ActivityID) {
                                            rAllocNew = act.Alloc;
                                        }
                                    });
                                }
                                /**
                                 * Set Alloc value of appropriate ActivityId
                                 */
                                newArr.push({
                                    Alloc: rAllocNew,
                                    ActivityID: value.ActivityID
                                });
                            }
                        }
                    }
                } else if (!isAllocExist && curpersonAlloc == null) {
                  /**
                   * Set if current persons Alloc values is 0 set the registration alloc value into 0 and pushed into an array
                   */
                    for (let [key, value] of Object.entries(reg.Activities)) {
                        if (value.Alloc != 0) {
                            newArr.push({
                                Alloc: 0,
                                ActivityID: value.ActivityID
                            });
                        }
                    }
                }
                /**
                 *  Update registration activities Alloc field 
                 */
                if (newArr.length) {
                    setIsUpdateLoading(true);
                    /**
                     * Update function
                     */
                    await actions.registrations
                        .addAllocToRegistrations({
                            PersonID: curPerson.PersonID,
                            Month: curMonth,
                            Activities: newArr,
                        })
                        .then((hash) => {
                            setIsUpdateLoading(false);
                            setHasChanged(true);
                            window.location.reload();
                        });
                }
            }
        };
        addAllocFields();
    } catch (error) {
        console.log(error);
    }
}, [curMonth, reg, params.month]);
  // EOF 573 - Add or Update Alloc field on OnLoad the registration
  
  if (!curPerson || !curMonth || !reg) return null;

  const alloc = getPersonAlloc(curMonth, curPerson.Allocations || []);

  // Update Alloc Fields
  const onUpdateClick = async (values: any) => {
    try {
      // Your asynchronous code here  
      setIsUpdateLoading(true);
      setHasChanged(true);
      var newArr = [];
      for (let [key, value] of Object.entries(values)) {
        if (typeof value === 'number') {
          newArr.push({Alloc: value, ActivityID: key});
        }
      } 
      await actions.registrations.updateRegistrations({
        PersonID: curPerson.PersonID,
        Month: curMonth,  
        Activities: newArr
      });

    } catch (error) {
      console.error(error);
    }
    finally {
      setIsUpdateLoading(false)
    }
  };
// Update Alloc Fields - Fails
  const onUpdateClickFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  async function onGodkend(values: any) {
    if (!curPerson || !curMonth) return;

    setLoading(true);
    try {
      await actions.registrations.accept({
        PersonID: curPerson.PersonID,
        Month: curMonth,
        AM: values.AM,
        ATP: values.ATP,
        Pension: values.Pension,
        Ferie: values.Ferie
      });
    }
    finally {
      setLoading(false)
    }
  }

  async function onAfvisClick() {
    if (!curPerson || !curMonth) return;

    const reason = window.prompt("Angiv årsag:");
    if (!reason) return;

    setLoading(true);
    try {
      await actions.registrations.reject({
        PersonID: curPerson.PersonID,
        Month: curMonth,
        Reason: reason
      });
    }
    finally {
      setLoading(false)
    }
  }

  async function onLaasOpClick() {
    if (!curPerson || !curMonth) return;

    setLoading(true);
    try {
      await actions.registrations.unlock({
        PersonID: curPerson.PersonID,
        Month: curMonth
      });
    }
    finally {
      setLoading(false)
    }
  }
  // Remove Pay Slip - Fjern lønseddel
  async function onFjernLønseddel() {
    if (!curPerson || !curMonth) return;
     setFjernLoading(true);
     try {  
      await actions.registrations.updateLonseddel({
        PersonID: curPerson.PersonID,
        Month: curMonth
      });
    }
    finally {
      setFjernLoading(false)
      message.success("Lønseddel blev fjernet");
    }
  }

  const isLocked = !(reg.State === "submitted" || reg.State === "unlocked");

  const afregning: Afregning = curPersonOrg?.Afregning || "standardsats"
  let shouldSign = alloc == null && reg.State != "unlocked";
  if (shouldSign) {
    const hasLines = reg.Activities?.some(a => a.Lines && a.Lines.length > 0)
    if (!hasLines) shouldSign = false;
  }


  type Line = RegistrationActivityLine & { key: any, ActivityID: string }
  let iter = 0
  const tableData: Line[] = [];
  for (const act of reg.Activities || []) {
    for (const line of act.Lines || []) {
      tableData.push({
        ...line,
        ActivityID: act.ActivityID,
        key: iter++
      })
    }
  }

  const regColumns: ColumnsType<Line> = [
    {
      title: "Dato",
      sorter: (a, b) => a.Day! - b.Day!,
      defaultSortOrder: "ascend",
      render: (_, v) => monthToMoment(reg.Month).date(v.Day!).format("DD.MM.YYYY"),
    },
    {
      title: "Arbejdsbeskrivelse",
      dataIndex: "Description",
      filterIcon: <BiSearch size={16} />,
      onFilter: (f: any, o) => {
        return o.Description!.toLowerCase().includes(f);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        function onInput(e: any) {
          setSelectedKeys(e.target.value ? [e.target.value.toLowerCase()] : []);
          confirm({ closeDropdown: false });
        }
        return (
          <div style={{ padding: 10 }}>
            <Input placeholder="Søg..." allowClear onChange={onInput} suffix={<BiSearch />} />
          </div>
        )
      },
    },
    {
      title: "Timer",
      align: "right",
      sorter: (a, b) => a.Hours! - b.Hours!,
      render: (_, v) => v.Hours?.toLocaleString() || "-"
    },
    {
      title: "Projekt",
      onFilter: (f, o) => o.ActivityID === f,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

        function onStatusClear() {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
        }
        function onStatusChange(status: any) {
          setSelectedKeys(status);
          confirm({ closeDropdown: false });
        }

        return (
          <div style={{ padding: 10 }}>
            <Select tagRender={v => <Tag><Row gutter={5} align="middle"><Col>{v.label}</Col><Col><BiXCircle style={{ cursor: "pointer" }} onClick={() => v.onClose()} /></Col></Row></Tag>} showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
              {
                (reg.Activities || []).map(r => {
                  const projAlias = projectAliasFromActivity(r.ActivityID)
                  const proj = projs?.find(p => p.Alias === projAlias)
                  if (!proj) return
                  const act = proj.Activities?.find(a => a.ActivityID === r.ActivityID)
                  if (!act) return

                  return <Select.Option key={r.ActivityID}><Name name={proj.Name} details={[act.Name]} /></Select.Option>
                })
              }
            </Select>
          </div>
        )
      },
      render: (_, v) => {
        const projAlias = projectAliasFromActivity(v.ActivityID)
        const proj = projs?.find(p => p.Alias === projAlias)
        if (!proj) return "-"
        const act = proj.Activities?.find(a => a.ActivityID === v.ActivityID)
        if (!act) return "-"

        return <Name name={proj.Name} details={[act.Name]} />
      }
    },
  ]

  return (
    <Col flex={1}>
      <Row gutter={0}>
        <Col flex="1 0 30%">
          <Row>
            <PersonHeader
              onOrgClick={o => navigate(`/controlling/registrering?org=${o.CVR}`)}
              onPersonSelect={p => p ? navigate(`/controlling/registrering/${p.Email}/${formatMonthShort(curMonth)}`) : navigate("/controlling/registrering")}
              onMonthSelect={m => m ? navigate(`/controlling/registrering/${curPerson.Email}/${formatMonthShort(m)}`) : null}
              showMonth
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col flex={1}>
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Link to={`/registrering/${params.email}/${params.month}`}><Button>Gå til registrering</Button></Link>
              </Row>
              <Row justify="center">
                <Col><StatusAlert reg={reg} /></Col>
              </Row>
              <Form onValuesChange={v => console.log(v)} onFinish={onGodkend} labelCol={{ span: 8 }} initialValues={reg} key={reg.RegistrationID}>
                {
                  curPersonOrg?.Afregning == "faktisk-lon" &&
                  <>
                    <Form.Item name="AM" label="AM-indkomst" rules={[{ required: true, message: "Påkrævet" }]}>
                      <NumberInput addonAfter="Kr." disabled={isLocked || loading} min={0} precision={2} />
                    </Form.Item>
                    <Form.Item name="ATP" label="ATP bidrag" rules={[{ required: true, message: "Påkrævet" }]}>
                      <NumberInput addonAfter="Kr." disabled={isLocked || loading} min={0} precision={2} />
                    </Form.Item>
                    <Form.Item name="Pension" label="Pensionsbidrag" rules={[{ required: true, message: "Påkrævet" }]}>
                      <NumberInput addonAfter="Kr." disabled={isLocked || loading} min={0} precision={2} />
                    </Form.Item>
                    <Form.Item name="Ferie" label="Feriepenge" rules={[{ required: true, message: "Påkrævet" }]}>
                      <NumberInput addonAfter="Kr." disabled={isLocked || loading} min={0} precision={2} />
                    </Form.Item>
                  </>
                }
                {
                  isLocked ?
                    <Row justify="center" style={{ marginTop: 20 }}>
                      <Button type="primary" onClick={onLaasOpClick}>Lås op</Button>
                    </Row>
                    :
                    <Row gutter={10} justify="center">
                      <Col flex="1 1 100px">
                        <Button loading={loading} style={{ width: "100%" }} type="primary" htmlType="submit" disabled={isLocked}>
                          {shouldSign ? "Send til underskrift" : "Godkend"}
                        </Button>
                      </Col>
                      <Col flex="1 1 100px"><Button style={{ width: "100%" }} type="primary" danger onClick={onAfvisClick} loading={loading} disabled={isLocked}>Afvis</Button></Col>
                    </Row>
                }
              </Form>
            </Col>
          </Row>
          <Col flex="0 0 30px">
            <Divider type="horizontal" style={{ margin: "20px 0px 0px 0px", height: "100%" }} />
          </Col> 
          {
            reg.Lonseddel && // Remove Pay Slip - Fjern lønseddel
            <>
              <Row gutter={10} justify="center" style={{ marginTop: 20 }}> 
                <Col flex="1 1 100px" lg={{ span: 9, offset: 3 }} style={{ fontWeight: 600,marginTop: "6px" }}>
                Fjerne dokumenter
                </Col>
                <Col flex="1 1 100px">
                  <Button style={{ width: "100%" }} type="primary" danger onClick={onFjernLønseddel} loading={fjernLoading} disabled={isLocked}>Fjern Lønseddel</Button>
                </Col>
              </Row>
              <Col flex="0 0 30px">
                <Divider type="horizontal" style={{ margin: "20px 0px 0px 0px", height: "100%" }} />
              </Col> 
            </> 
          }
          {/* Sub Projects and allocations */}
          {
           reg &&
            <Row style={{ marginTop: 0 }}>
              <Col flex={1}>
                <Row justify="center">
                  <Col>&nbsp;</Col>
                </Row>
                <Form
                  labelCol={{ span: 12 }}
                  name="basic"
                  onFinish={onUpdateClick}
                  onFinishFailed={onUpdateClickFailed}
                  onValuesChange={v => console.log(v)} 
                  initialValues={reg} 
                  key={reg.RegistrationID}
                >
                  {
                    <>
                      <Row justify="center" style={{ marginTop: 20, fontWeight: 600 }}>
                        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                          UNDERPROJEKT
                        </Col>
                        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 4 }}>
                          ALLOKERING
                        </Col>
                      </Row>
                    </>
                  }
                  {
                    (reg.Activities || []).map((r, index) => {
                      const projAlias = projectAliasFromActivity(r.ActivityID)
                      const proj = projs?.find(p => p.Alias === projAlias)
                      if (!proj) return ""
                      const act = proj.Activities?.find(a => a.ActivityID === r.ActivityID)
                      if (!act) return ""

                      var rAllocNew = r.Alloc ? r.Alloc : 0;
                      
                      return <>
                        <Row justify="center" key={r.ActivityID} style={{ marginTop: 20 }}>
                          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 1 }}>
                            {proj.Name}<br />
                            <small>{act.Name}</small>
                            {/*&nbsp;::<small>{ r.ActivityID }</small>*/}
                          </Col>
                          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 4 }}>
                            <Form.Item
                              key={index}
                              name={r.ActivityID}
                              rules={[{ required: true, message: 'Please input a number!' }]}
                              initialValue={rAllocNew}
                            >
                              <InputNumber
                                min={0}
                                disabled={isUpdateLoading}
                                // disabled={true}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    })
                  }
                  <Row justify="space-between" style={{ marginTop: 30, marginLeft: 50 }}>
                    <Form.Item>
                      <Button loading={isUpdateLoading} type="primary" htmlType="submit">
                        Opdatering
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
            </Row>
          }
          {/*  */}
        </Col>
        <Col flex="0 0 60px">
          <Divider type="vertical" style={{ margin: "0 30px", height: "100%" }} />
        </Col>        
        <Col flex="1 1 calc(70% - 60px - 10px)">
          <Tabs style={{ marginTop: 30 }}>
            {
              !alloc &&
              <Tabs.TabPane key="timeseddel" tab="Timeseddel">
                <Table size="small" columns={regColumns} dataSource={tableData} pagination={false}
                  summary={lines => {
                    let totalHours = 0;
                    for (const l of lines) {
                      totalHours += l.Hours || 0
                    }
                    return (
                      <Table.Summary.Row style={{ fontWeight: "bold" }}>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="right">{totalHours.toLocaleString()}</Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      </Table.Summary.Row>
                    );
                  }}
                />
              </Tabs.TabPane>
            }
            {
              (afregning == "faktisk-lon" && reg.Lonseddel) &&
              <Tabs.TabPane key="lønseddel" tab="Lønseddel">
                <PDFViewer
                  key={`${curPerson.Email} ${formatMonthShort(curMonth)}`}
                  getUrl={() => actions.registrations.getLonseddelDownloadLink(curPerson.PersonID, curMonth)}
                  style={{ height: "calc(100vh - 138px)" }}
                />
              </Tabs.TabPane>

            }
            {
              alloc &&
              <Tabs.TabPane key="allokering" tab="Allokering">
                <PDFViewer
                  key={`${curPerson.Email} ${formatMonthShort(curMonth)}`}
                  getUrl={() => actions.persons.getAllocationDownloadLink(curPerson.PersonID, alloc.Contract.CaseFile)}
                  style={{ height: "calc(100vh - 138px)" }}
                />
              </Tabs.TabPane>
            }
            <Tabs.TabPane key="history" tab="Historik">
              <Row justify="center">
                <Col flex="0 1 600px">
                  <Log log={reg.Log || []} />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row >
    </Col >
  )
}
