import { Col, Dropdown, Input, Menu, Row } from "antd";
import { useState } from "react";
import { BiCaretDown, BiSearch } from "react-icons/bi";
import { useCurProject, useProjects } from "../../../../utils/store";
import Name from "../../../shared/Name";

type Props = {
  onProjSelect?: (proj: Project | null) => any
}

export default function ProjectHeader(props: Props) {
  const projs = useProjects();
  const curProj = useCurProject();

  const [search, setSearch] = useState("");

  if (!curProj) return null;

  function onOrgSelect(i: any) {
    if (!projs) return

    const newOrg = projs.find(p => p.ProjectID === i.key) || null;
    props.onProjSelect?.(newOrg);
  }

  if (!projs) return null;

  let dropdown: JSX.Element | null = null;

  if (projs.length > 1) {
    dropdown = (
      <Menu onClick={onOrgSelect} selectedKeys={curProj ? [curProj.ProjectID] : []}>
        <Menu.Item key="search">
          <Input autoFocus onClick={e => e.stopPropagation()} value={search} onChange={e => setSearch(e.target.value)} type="search" suffix={<BiSearch />} allowClear />
        </Menu.Item>
        {projs
          .filter(p => {
            if (search.length === 0) return true;
            return `${p.Name || ""}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          })
          .map(p =>
            <Menu.Item key={p.ProjectID}>
              <Name name={p.Name} details={[p.Bevilling == "REACTRF-210029" ? "Regionalfond" : "Socialfond"]} />
            </Menu.Item>
          )}
      </Menu>
    )
  }

  return (
    <Col flex={1}>
      <Row align="middle" justify="space-between">
        <Col>
          <Name name={curProj.Name} details={[curProj.Bevilling == "REACTRF-210029" ? "Regionalfond" : "Socialfond"]} header dropdown={dropdown} />
        </Col>
      </Row>
    </Col>
  )
}