import { Button, Col, Input, Row, Select, Table, TableColumnsType } from "antd";

import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router";
import { actions, store, useOrganisations, usePersons, useRegistrations } from "../../../utils/store";
import { formatMonth, formatMonthShort, monthToMoment } from "../../../utils/utils";
import RegStatusTag from "../registrering/components/RegStatusTag";

export default function ControllingRegistrering() {
    const orgs = useOrganisations();
    const persons = usePersons();
    const regs = useRegistrations()?.filter(r => r.State !== "missing");

    const navigate = useNavigate()

    if (!persons || !orgs) return null;

    function CustomRow(rowProps: any, extra: any) {
        if (!regs || !persons) return null;

        const id = rowProps["data-row-key"];
        const reg = regs.find(r => r.RegistrationID === id);
        if (!reg) return null;
        const pers = persons.find(p => p.PersonID === reg.PersonID)!;
        if (!pers) return null;

        return (
            <tr {...rowProps} onClick={() => navigate(`/controlling/registrering/${pers.Email}/${formatMonthShort(reg.Month)}`)} style={{ cursor: "pointer" }} />
        )
    }

    const columns: TableColumnsType<Registration> = [
        {
            title: "Person",
            dataIndex: "PersonID",
            filterIcon: <BiSearch size={16} />,
            sorter: (a, b) => {

                const persA = persons.find(p => p.PersonID === a.PersonID);
                const persB = persons.find(p => p.PersonID === b.PersonID);

                if (persA?.FullName == null) return 1;
                if (persB?.FullName == null) return -1;
                return persA.FullName.localeCompare(persB.FullName);
            },
            onFilter: (f: any, o) => {
                const pers = persons.find(p => p.PersonID === o.PersonID);
                const org = pers?.OrganisationID ? orgs.find(o => o.OrganisationID === pers.OrganisationID) : undefined;
                const searchStr = `${pers?.FullName || ""} ${pers?.Email || ""} ${org?.Name || ""} ${org?.CVR || ""}`;
                return searchStr.toLowerCase().includes(f);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                function onInput(e: any) {
                    setSelectedKeys(e.target.value ? [e.target.value.toLowerCase()] : []);
                    confirm({ closeDropdown: false });
                }
                return (
                    <div style={{ padding: 10 }}>
                        <Input placeholder="Søg..." allowClear onChange={onInput} suffix={<BiSearch />} />
                    </div>
                )
            },
            render: (v, o) => {
                const pers = persons.find(p => p.PersonID === v);
                const org = pers?.OrganisationID ? orgs.find(o => o.OrganisationID === pers.OrganisationID) : undefined;

                return <>
                    <Row style={{ fontWeight: 600 }}>
                        {pers?.FullName ? <div>{pers.FullName}</div> : "<fejl>"}
                    </Row>
                    <Row style={{ fontSize: 12 }}>
                        {org && org.Name}
                    </Row>
                </>
            }
        },
        {
            title: "Måned",
            dataIndex: "Month",
            defaultSortOrder: "ascend",
            sorter: (a, b) => {
                return monthToMoment(a.Month).diff(monthToMoment(b.Month))
            },
            onFilter: (f: any, o) => {
                const year = parseInt(f.year);
                const months = f.months.map((m: any) => parseInt(m));
                return (o.Month.Year === year && (months.length == 0 || months.includes(o.Month.Month)))
            },
            filterDropdown: function MonthDropDown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
                const [years, setYears] = useState<any[]>([]);
                const [months, setMonths] = useState<any[]>([]);

                function confirmFilter(years: any[], months: any[]) {
                    let tmpYears = years;
                    if (tmpYears.length == 0 && months.length > 0)
                        tmpYears = [2021, 2022, 2023];

                    setSelectedKeys(tmpYears.map(y => ({ year: y, months })) as any);
                    confirm({ closeDropdown: false });
                }
                function onYearChange(years: any[]) {
                    setYears(years);
                    confirmFilter(years, months);
                }
                function onYearClear() {
                    const years: any[] = [];
                    setYears(years);
                    confirmFilter(years, months)
                }

                function onMonthChange(months: any[]) {
                    const monthMap: any = { "januar": 1, "februar": 2, "marts": 3, "april": 4, "maj": 5, "juni": 6, "juli": 7, "august": 8, "september": 9, "oktober": 10, "november": 11, "december": 12 };
                    months = months.map((m: string) => monthMap[m]);
                    setMonths(months);
                    confirmFilter(years, months);
                }
                function onMonthClear() {
                    const months: any[] = [];
                    setMonths(months);
                    confirmFilter(years, months)
                }

                return (
                    <div style={{ padding: 10 }}>
                        <Row>År:</Row>
                        <Row>
                            <Select showArrow onChange={onYearChange} onClear={onYearClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear >
                                <Select.Option key={2021}>2021</Select.Option>
                                <Select.Option key={2022}>2022</Select.Option>
                                <Select.Option key={2023}>2023</Select.Option>
                            </Select>
                        </Row>
                        <Row>Måned:</Row>
                        <Row>
                            <Select showArrow onChange={onMonthChange} onClear={onMonthClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear>
                                <Select.Option key="januar">Januar</Select.Option>
                                <Select.Option key="februar">Februar</Select.Option>
                                <Select.Option key="marts">Marts</Select.Option>
                                <Select.Option key="april">April</Select.Option>
                                <Select.Option key="maj">Maj</Select.Option>
                                <Select.Option key="juni">Juni</Select.Option>
                                <Select.Option key="juli">Juli</Select.Option>
                                <Select.Option key="august">August</Select.Option>
                                <Select.Option key="september">September</Select.Option>
                                <Select.Option key="oktober">Oktober</Select.Option>
                                <Select.Option key="november">November</Select.Option>
                                <Select.Option key="december">December</Select.Option>
                            </Select>
                        </Row>
                    </div>
                )
            },
            render: (v, o) => formatMonth(v)
        },
        {
            title: "Status",
            dataIndex: "status",
            defaultFilteredValue: ["submitted", "rejected", "needs-signing", "unlocked"],
            sorter: (a, b) => {
                return a.State.localeCompare(b.State)
            },
            onFilter: (f, o) => o.State === f,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

                function onStatusClear() {
                    setSelectedKeys([]);
                    confirm({ closeDropdown: false });
                }
                function onStatusChange(status: any) {
                    setSelectedKeys(status);
                    confirm({ closeDropdown: false });
                }

                return (
                    <div style={{ padding: 10 }}>
                        <Select showArrow onChange={onStatusChange} onClear={onStatusClear} mode="multiple" placeholder="Alle" style={{ width: 200 }} allowClear defaultValue={["submitted", "rejected", "needs-signing", "unlocked"]}>
                            <Select.Option key="submitted">Indsendt</Select.Option>
                            <Select.Option key="rejected">Afvist</Select.Option>
                            <Select.Option key="needs-signing">Mangler Underskrift</Select.Option>
                            <Select.Option key="unlocked">Oplåst</Select.Option>
                            <Select.Option key="accepted">Godkendt</Select.Option>
                        </Select>
                    </div>
                )
            },
            render: (v, o) => <RegStatusTag reg={o} />
        }
    ];

    async function onExportOrganisations(bevilling: Bevilling) {
        const url = await actions.organisations.getExportDownloadLink(bevilling);
        window.open(url, "_blank");
    }

    async function onExportPersons(bevilling: Bevilling) {
        const url = await actions.persons.getExportDownloadLink(bevilling);
        window.open(url, "_blank");
    }

    async function onExportRegistrations(bevilling: Bevilling) {
        const url = await actions.registrations.getExportDownloadLink(bevilling);
        window.open(url, "_blank");
    }

    async function onExportAllRegistrations() {
        const url = await actions.registrations.getAllExportDownloadLink();
        window.open(url, "_blank");
    }

    async function onExportAllAllocation() {
        const url = await actions.persons.getAllAllocationDownloadLink();
        window.open(url, "_blank");
    }

    return (
        <Col flex="0 1 1200px">
            <Table size="small" columns={columns} dataSource={regs} rowKey="RegistrationID" components={{ body: { row: CustomRow } }} pagination={false} />
            <Row style={{ marginTop: 30 }} gutter={10} justify="center">
                <Col>Regionalfond</Col>
                <Col><Button onClick={() => onExportOrganisations("REACTRF-210029")}>Eksportér organisationer</Button></Col>
                <Col><Button onClick={() => onExportPersons("REACTRF-210029")}>Eksportér personer</Button></Col>
                <Col><Button onClick={() => onExportRegistrations("REACTRF-210029")}>Eksportér registreringer</Button></Col>
            </Row>
            <Row style={{ marginTop: 30 }} gutter={10} justify="center">
                <Col>Socialfond</Col>
                <Col><Button onClick={() => onExportOrganisations("REACTSF-210031")}>Eksportér organisationer</Button></Col>
                <Col><Button onClick={() => onExportPersons("REACTSF-210031")}>Eksportér personer</Button></Col>
                <Col><Button onClick={() => onExportRegistrations("REACTSF-210031")}>Eksportér registreringer</Button></Col>
            </Row>
            <Row style={{ marginTop: 30 }} gutter={10} justify="center">
                <Col>Registrations</Col>
                <Col><Button onClick={() => onExportAllRegistrations()}>Download alle registreringer</Button></Col>
            </Row>
        </Col>
    )
}

