import { Col, Dropdown, Menu, Row } from "antd"
import Avatar from "antd/lib/avatar/avatar"
import { BiUser } from "react-icons/bi";
import { actions, useUser } from "../../utils/store";

type Props = {
  children?: JSX.Element | JSX.Element[]
}

export default function UserLayout(props: Props) {
  const user = useUser();

  function onLogout() {
    actions.user.logout();
  }

  if (!user) return <>{props.children}</>

  const userOverlay = (
    <Menu>
      <Row justify="center">
        <Col style={{ padding: "5px 12px" }}>
          {
            user.State == "ready" ?
              <>
                <div style={{ fontWeight: "bold", }}>{user.FullName}</div>
                <div style={{ marginTop: -6, marginBottom: 5 }}><small>{user.Email || "<email>"}</small></div>
                <div style={{ borderTop: "1px solid #aaa" }} />
              </>
              :
              <div style={{ fontWeight: "bold", }}>{user.Email}</div>
          }
        </Col>
      </Row>
      <Menu.Item key="logout" onClick={onLogout}>Log ud</Menu.Item>
    </Menu>
  );

  return (
    <>
      {props.children}
      <div style={{ position: "absolute", top: 10, right: 10, cursor: "pointer", zIndex: 2000 }}>
        <Dropdown overlay={userOverlay} trigger={['click']} >
          <Avatar size="large" style={{ backgroundColor: "#247d83", fontSize: "1.2em", boxShadow: "3px 3px 10px rgb(0 0 0 / 20%)" }}>
            {
              user.State == "ready" ?
                <>{user.FirstName![0].toUpperCase()}{user.LastName![0].toUpperCase()}</>
                :
                <BiUser />
            }

          </Avatar>
        </Dropdown>
      </div>
    </>
  )
}