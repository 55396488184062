import { Button, Col, Collapse, Descriptions, Dropdown, Menu, message, Popconfirm, Row, Table, TableColumnsType, Tabs, Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiDownload, BiHelpCircle, BiMailSend, BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { actions, useCurOrganisation, useCurOrganisationEmployees, useOrganisations, useProjects, useRoles, useUser } from "../../../utils/store";
import { formatMonth, formatMonthShort, getPersonAlloc, hasRole, momentToMonth, monthToMoment, projectEnd, projectStart, signatureFailed, signatureMissing, signaturePending, signatureSigned } from "../../../utils/utils";
import OrganisationHeader from "./components/OrganisationHeader";
import EmployeeAllocModal from "./components/EmpAllocModal";
import EmployeeGodkenderModal from "./components/EmpGodkenderModal";
import EmployeeInviteModal from "./components/EmpInviteModal";
import EmployeePeriodModal from "./components/EmpPeriodModal";
import Name from "../../shared/Name";
import SignatureStatus from "../../shared/SignatureStatus";
import LogPane from "../../shared/LogPane";

export default function OrganisationCVR() {
    const user = useUser();
    const roles = useRoles();
    const projs = useProjects();
    const orgs = useOrganisations();
    const curOrg = useCurOrganisation();
    const curEmps = useCurOrganisationEmployees();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showPeriodModal, setShowPeriodModal] = useState(false);
    const [showAllocModal, setShowAllocModal] = useState(false);
    const [showGodkenderModal, setShowGodkenderModal] = useState(false);
    const [showLog, setShowLog] = useState(false)

    const params = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        if (!orgs) return;

        const newOrg = orgs.find(o => o.CVR === params.cvr);
        if (newOrg) actions.selectOrganisation(newOrg.OrganisationID);
        else navigate(`/organisation`);

    }, [params.cvr, orgs])

    function onOrgSelect(org: Organisation | null) {
        if (!org) navigate(`/organisation`);
        else navigate(`/organisation/${org.CVR}`)
    }

    function onResendInvite(p: Person) {
        actions.organisations.resendInvite(p.PersonID)
            .then(() => { message.success(`Invitationen er blevet afsendt til ${p.Email}`) });
    }

    function onUninvite(p: Person) {
        actions.organisations.uninviteEmployee(p.PersonID)
            .then(() => { message.success(`${p.Email} er blevet fjernet`) });
    }


    async function onPeriodeClick(emp: Person) {
        await actions.selectPerson(emp.PersonID);
        setShowPeriodModal(true);
    }

    async function onAllocClick(emp: Person) {
        await actions.selectPerson(emp.PersonID);
        setShowAllocModal(true);
    }

    async function onGodkenderClick(emp: Person) {
        await actions.selectPerson(emp.PersonID);
        setShowGodkenderModal(true);
    }

    async function onDownload() {
        if (!curOrg) return;
        const link = await actions.organisations.getDocumentsDownloadLink(curOrg.OrganisationID)
        window.open(link, "_blank");
    }

    if (!curOrg) return null;

    let empColumns: TableColumnsType<Person> = [
        {
            title: "Navn",
            render: (v, o) =>
                o.State == "ready"
                    ? <Name link={`/registrering/${o.Email}`} name={o.FullName} details={[o.Email]} />
                    : <Name details={[o.Email]} name={
                        <>
                            <span style={{ opacity: 0.6 }}>{`<inviteret>`}</span>
                            {(o.State == "invited" || o.State == "invited-needs-godkender") &&
                                <>
                                    <Tooltip title="Gensend invitation">
                                        <Popconfirm title="Vil du gensende invitationen?" okText="Ja" cancelText="Nej" onConfirm={() => onResendInvite(o)}>
                                            <BiMailSend style={{ marginLeft: 5, cursor: "pointer" }} size={14} color="rgb(64, 169, 255)" />
                                        </Popconfirm>
                                    </Tooltip>
                                    <Tooltip title="Fjern invitation">
                                        <Popconfirm title="Er du sikker på du vil fjerne invitationen?" okText="Ja" cancelText="Nej" onConfirm={() => onUninvite(o)}>
                                            <BiTrash style={{ marginLeft: 5, cursor: "pointer" }} size={14} color="rgb(255, 77, 79)" />
                                        </Popconfirm>
                                    </Tooltip>
                                </>
                            }
                        </>
                    } />

        },
        {
            title: "Periode",
            render: (v, o) => {
                const start = monthToMoment(o.Start || curOrg.Start || projectStart);
                const end = monthToMoment(o.End || curOrg.End || projectEnd);
                return (
                    <Row justify="space-between">
                        <Col>{start.format("MM/YYYY")} - {end.format("MM/YYYY")}</Col>
                        <Col><Button type="link" size="small" onClick={() => onPeriodeClick(o)} ><Row align="middle"><BiPencil /></Row></Button></Col>
                    </Row>
                )
            }
        },
        {
            title: "Godkender",
            dataIndex: "Godkender",
            render: (v, o) => {
                return (
                    <Row justify="space-between">
                        <Col>{v ? <Tooltip title={v.Email}>{v.Name}</Tooltip> : "-"}</Col>
                        <Col><Button type="link" size="small" onClick={() => onGodkenderClick(o)} ><Row align="middle"><BiPencil /></Row></Button></Col>
                    </Row>
                )
            }
        }
    ]

    if (curOrg.Afregning === "faktisk-lon") {
        empColumns = [
            ...empColumns.slice(0, 3),
            {
                title: "Allokering",
                dataIndex: "allocs",
                render: (v, o) => {
                    if (o.State == "invited" || o.State == "invited-needs-godkender") return "-";

                    const alloc = getPersonAlloc(momentToMonth(moment()), o.Allocations, true, true)
                    const isFuture = moment(alloc?.Start).isAfter();

                    return (
                        <Row justify="space-between" align="middle" gutter={10}>
                            <Col>
                                {!alloc && <i style={{ color: "#aaa" }}>ingen</i>}
                                {(alloc && alloc.State == "") && <i style={{ color: "#aaa" }}>mangler underskrift</i>}

                                {(alloc && alloc.State == "active") && (isFuture
                                    ? <i style={{ color: "#aaa" }}>starter {moment(alloc?.Start).format("\\d. D. MMM YYYY")}</i>
                                    : <>{alloc.Activities?.reduce((p, c) => p + c.Alloc, 0)}%</>
                                )}

                            </Col>
                            <Col><Button type="link" size="small" onClick={() => onAllocClick(o)} ><Row align="middle"><BiPencil /></Row></Button></Col>
                        </Row>
                    )
                }
            },
            ...empColumns.slice(3)
        ];
    }

    const actColumns: TableColumnsType<OrganisationActivity> = [
        {
            title: "Aktivitet",
            dataIndex: "ActivityID",
            render: (v) => {
                const alias = v.split('.')[0];
                const proj = projs?.find(p => p.Alias == alias)
                if (!proj) return "-";
                const act = proj.Activities?.find(a => a.ActivityID == v)

                return <Name name={proj.Name} details={[act?.Name || "<ukendt>"]} />
            }
        },
        {
            title: "Status",
            dataIndex: "ActivityID",
            render: (v, o) => {
                const alias = v.split('.')[0];
                const proj = projs?.find(p => p.Alias == alias)
                if (!proj) return "-";
                const act = proj.Activities?.find(a => a.ActivityID == v)
                if (!act) return "-"

                if (act.ProjectManagement) return <Tag>De minimis ikke relevant</Tag>
                if (o.DeminimisSigned) return <Tag color="green">De minimis underskrevet</Tag>
                return <Tag color="red">De minimis ikke underskrevet</Tag>
            }
        }
    ];

    if (hasRole(roles, "admin")) {
        empColumns.push({ title: "ID", dataIndex: "PersonID" })
        empColumns.push({ title: "State", dataIndex: "State" })
        actColumns.push({ title: "ActivityID", dataIndex: "ActivityID" })
    }


    return (
        <Col flex="0 1 1200px">
            <Row>
                <OrganisationHeader onOrgSelect={onOrgSelect} />
            </Row>

            <Row>
                <Col flex={1} >
                    <Collapse style={{ fontSize: "0.75rem", marginTop: -32, pointerEvents: "none" }} ghost expandIconPosition="right" >
                        <Collapse.Panel header={<Col flex={1}><Row justify="end" align="middle"><span style={{ pointerEvents: "all" }}>Detaljer</span></Row></Col>} key={1}>
                            <Descriptions bordered size="small" style={{ pointerEvents: "all" }}>
                                <Descriptions.Item label="CVR-nr.">{curOrg.CVR || "<mangler>"}</Descriptions.Item>
                                <Descriptions.Item label="P-nr.">{curOrg.Pno || "<mangler>"}</Descriptions.Item>
                                <Descriptions.Item label="Adresse">{`${curOrg.Address}, ${curOrg.Zip} ${curOrg.City}`}</Descriptions.Item>
                                <Descriptions.Item label="Periode">
                                    {formatMonth(curOrg.Start || projectStart)} - {formatMonth(curOrg.End || projectEnd)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Afregning">
                                    {curOrg.Afregning == "faktisk-lon" && "Faktisk løn"}
                                    {curOrg.Afregning == "standardsats" && "Standardsats"}
                                    {curOrg.Afregning == null && "<mangler>"}
                                </Descriptions.Item>
                                <Descriptions.Item label={
                                    <Tooltip title="Den støtte, som organisationen har modtaget inden påbegyndelse i projektet">
                                        <Row align="middle">De minimis <BiHelpCircle style={{ marginLeft: 5 }} /></Row>
                                    </Tooltip>
                                } >
                                    {curOrg.SMV
                                        ? (curOrg.Deminimis.Lines?.reduce((p, c) => p + c.Amount, 0) || 0) + " kr."
                                        : "Ikke en SMV"}
                                </Descriptions.Item>
                                <Descriptions.Item label="Aftale">
                                    <SignatureStatus signature={curOrg.Aftale} fake={curOrg.Type == "vip"} details />
                                    {
                                        (
                                            curOrg.Aftale?.SignedAt &&
                                            (user?.OrganisationID === curOrg.OrganisationID || hasRole(roles, "project-manager") || hasRole(roles, "controller"))
                                        ) &&
                                        <Button onClick={onDownload} size="small" type="link"><BiDownload /></Button>
                                    }

                                </Descriptions.Item>

                                <Descriptions.Item label="Tegningsberettiget">
                                    <Row style={{ fontWeight: 600, lineHeight: 1.2 }}>{curOrg.Tegningsberettiget.Name}</Row>
                                    <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{curOrg.Tegningsberettiget.Email}</Row>
                                </Descriptions.Item>
                                <Descriptions.Item label="State">{curOrg.State}</Descriptions.Item>
                                <Descriptions.Item label="Type">{curOrg.Type}</Descriptions.Item>
                                <Descriptions.Item label="Organisations ID">{curOrg.OrganisationID}</Descriptions.Item>
                                <Descriptions.Item label="Historik"><Button size="small" onClick={() => setShowLog(true)}>Se historik</Button></Descriptions.Item>

                            </Descriptions>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row>
                <Col flex={1}>
                    <Tabs>
                        <Tabs.TabPane tab="Medarbejdere" key="medarbejdere">
                            <Row style={{ marginBottom: 20 }}>
                                <Col flex={1}>
                                    <Table size="small" columns={empColumns} rowKey="PersonID" dataSource={curEmps} pagination={false} />
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col>
                                    <Button onClick={() => setShowAddModal(true)}>Invitér medarbejder</Button>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Aktiviteter" key="aktiviteter">
                            <Row style={{ marginBottom: 20 }}>
                                <Col flex={1}>
                                    <Table size="small" columns={actColumns} rowKey="ActivityID" dataSource={curOrg.Activities || []} pagination={false} />
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>




            <EmployeeInviteModal visible={showAddModal} onClose={() => setShowAddModal(false)} />
            <EmployeePeriodModal visible={showPeriodModal} onClose={() => setShowPeriodModal(false)} />
            <EmployeeAllocModal visible={showAllocModal} onClose={() => setShowAllocModal(false)} />
            <EmployeeGodkenderModal visible={showGodkenderModal} onClose={() => setShowGodkenderModal(false)} />

            <LogPane log={curOrg.Log} visible={showLog} onClose={() => setShowLog(false)} />
        </Col >
    )
}