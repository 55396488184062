import { Col, Dropdown, Input, Menu, Row } from "antd";
import { useState } from "react";
import { BiCaretDown, BiSearch } from "react-icons/bi";
import { useCurOrganisation, useOrganisations } from "../../../../utils/store";
import Name from "../../../shared/Name";

type Props = {
  onOrgSelect?: (org: Organisation | null) => any
}

export default function OrganisationHeader(props: Props) {
  const orgs = useOrganisations();
  const curOrg = useCurOrganisation();

  const [search, setSearch] = useState("");

  if (!curOrg) return null;

  function onOrgSelect(i: any) {
    if (!orgs) return

    const newOrg = orgs.find(o => o.OrganisationID === i.key) || null;
    props.onOrgSelect?.(newOrg);
  }

  // function Name({ caret }: { caret?: boolean }) {
  //   if (!curOrg) return null;

  //   return (
  //     <Col>
  //       <Row align="middle" style={{ fontSize: 16, fontWeight: 700, lineHeight: 1 }}>
  //         {curOrg.Name}
  //         {caret && <BiCaretDown style={{ marginTop: 2 }} fontSize={16} />}
  //       </Row>
  //       <Row style={{ fontSize: 12 }}>CVR: {curOrg.CVR}</Row>
  //     </Col>
  //   )
  // }

  if (!orgs) return null;

  let nameEl: JSX.Element | null = null;
  // if (orgs.length <= 1) nameEl = <Name />
  // if (orgs.length > 1) {
  //   nameEl = (
  //     <>
  //       <Dropdown
  //         trigger={["click"]}
  //         destroyPopupOnHide
  //         overlay={
  //           <Menu onClick={onOrgSelect} selectedKeys={curOrg ? [curOrg.OrganisationID] : []}>
  //             <Menu.Item key="search">
  //               <Input autoFocus onClick={e => e.stopPropagation()} value={search} onChange={e => setSearch(e.target.value)} type="search" suffix={<BiSearch />} allowClear />
  //             </Menu.Item>
  //             {orgs
  //               .filter(o => {
  //                 if (search.length === 0) return true;
  //                 return `${o.Name || ""} ${o.CVR || ""} ${o.Pno || ""}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  //               })
  //               .map(o =>
  //                 <Menu.Item key={o.OrganisationID}>
  //                   <Row style={{ lineHeight: 1.1, fontWeight: 600 }}>{o.Name}</Row>
  //                   <Row style={{ lineHeight: 1.1, fontSize: 12 }}>CVR: {o.CVR}</Row>
  //                 </Menu.Item>
  //               )}
  //           </Menu>
  //         }
  //       >
  //         <Row align="top" style={{ display: "inline-flex", cursor: "pointer" }}><Name caret /></Row>
  //       </Dropdown>
  //     </>
  //   );
  // }

  let dropdown: JSX.Element | null = null;

  if (orgs.length > 1) {
    dropdown = (
      <Menu onClick={onOrgSelect} selectedKeys={curOrg ? [curOrg.OrganisationID] : []}>
        <Menu.Item key="search">
          <Input autoFocus onClick={e => e.stopPropagation()} value={search} onChange={e => setSearch(e.target.value)} type="search" suffix={<BiSearch />} allowClear />
        </Menu.Item>
        {orgs
          .filter(o => {
            if (search.length === 0) return true;
            return `${o.Name || ""} ${o.CVR || ""} ${o.Pno || ""}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          })
          .map(o =>
            <Menu.Item key={o.OrganisationID}>
              <Name name={o.Name} details={[`CVR: ${o.CVR}`]} />
            </Menu.Item>
          )}
      </Menu>
    )
  }


  return (
    <Col flex={1}>
      <Row align="middle" justify="space-between">
        <Col>
          <Name name={curOrg.Name} details={[`CVR: ${curOrg.CVR}`]} dropdown={dropdown} header />
        </Col>
      </Row>
    </Col>
  )
}