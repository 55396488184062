import { Col, Row } from "antd";
// @ts-ignore
import splash from "../../../assets/splash.jpg";

type Props = {
  children?: JSX.Element | JSX.Element[]
}

export default function SplashLayout(props: Props) {
  return (
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundImage: `url(${splash})`, backgroundSize: "cover" }}>
      {props.children}
      <div style={{ position: "fixed", right: 10, bottom: 5, color: "white", zIndex: 9999, opacity: 0.6 }}>
        <Row justify="end">
          <Col style={{ fontSize: 12 }}>
            Foto af <strong><a style={{ color: "white" }} target="_blank" href="https://www.pexels.com/da-dk/@punttim?utm_content=attributionCopyText&amp;utm_medium=referral&amp;utm_source=pexels">Tim Gouw</a></strong>
          </Col>
        </Row>
        <Row>
          <Col style={{ fontSize: 12 }}>
            Logo af <strong><a style={{ color: "white" }} target="_blank" href="https://www.flaticon.com/free-icons/lighthouse">Freepik - Flaticon</a></strong>
          </Col>
        </Row>
      </div>
    </div>
  )
}