import { useSelector } from "react-redux";
import { createStore } from "redux"
import { rpc } from "./utils";

type State = {
    user: Person | null
    projects: Project[] | null
    organisations: Organisation[] | null
    persons: Person[] | null
    registrations: Registration[] | null

    curProjectId: string | null
    curActId: string | null
    curOrganisationId: string | null
    curPersonId: string | null
    curMonth: Month | null
}

function defaultState(): State {
    return {
        user: null,
        projects: null,
        organisations: null,
        persons: null,
        registrations: null,

        curProjectId: null,
        curActId: null,
        curOrganisationId: null,
        curPersonId: null,
        curMonth: null
    }
}

function reducer(state = defaultState(), action: any) {
    switch (action.type) {
        case "SET_USER":
            state = { ...state, user: action.value }
            break;
        case "SET_PROJECTS":
            state = { ...state, projects: action.value }
            break;
        case "SET_ORGANISATIONS":
            state = { ...state, organisations: action.value }
            break;
        case "SET_PERSONS":
            state = { ...state, persons: action.value }
            break;
        case "SET_REGISTRATIONS":
            state = { ...state, registrations: action.value }
            break;

        case "SET_CUR_PROJECT_ID":
            state = { ...state, curProjectId: action.value }
            break;
        case "SET_CUR_ACTIVITY_ID":
            state = { ...state, curActId: action.value }
        case "SET_CUR_ORGANISATION_ID":
            state = { ...state, curOrganisationId: action.value }
            break;
        case "SET_CUR_PERSON_ID":
            state = { ...state, curPersonId: action.value }
            break;
        case "SET_CUR_MONTH":
            state = { ...state, curMonth: action.value }
            break;
    }

    return state;
}

export function useUser(): Person | null {
    return useSelector<State, Person | null>(s => s.user)
}
export function useProjects(): Project[] | null {
    return useSelector<State, Project[] | null>(s => s.projects)
}
export function useOrganisations(): Organisation[] | null {
    return useSelector<State, Organisation[] | null>(s => s.organisations)
}
export function usePersons(): Person[] | null {
    return useSelector<State, Person[] | null>(s => s.persons)
}
export function useRegistrations(): Registration[] | null {
    return useSelector<State, Registration[] | null>(s => s.registrations)
}
export function useRoles(): Role[] {
    const user = useUser();
    const userOrg = useUserOrganisation();
    return [...(userOrg?.Roles || []), ...(user?.Roles || [])];
}

export function useUserOrganisation(): Organisation | null {
    const orgs = useOrganisations()
    const user = useUser()

    if (!user || !orgs) return null
    if (!user.OrganisationID) return null
    return orgs.find(o => o.OrganisationID === user.OrganisationID) || null
}
export function useCurOrganisation(): Organisation | null {
    const orgs = useOrganisations();
    const curOrgId = useSelector<State, string | null>(s => s.curOrganisationId)
    if (!curOrgId || !orgs) return null;
    return orgs.find(o => o.OrganisationID === curOrgId) || null
}
export function useCurOrganisationEmployees(): Person[] {
    const curOrg = useCurOrganisation();
    const persons = usePersons();
    if (!curOrg || !persons) return [];
    return persons.filter(p => p.OrganisationID == curOrg.OrganisationID)
}

export function useCurPerson(): Person | null {
    const pers = usePersons();
    const curPersId = useSelector<State, string | null>(s => s.curPersonId)
    if (!curPersId || !pers) return null;
    return pers.find(p => p.PersonID === curPersId) || null
}
export function useCurPersonOrganisation(): Organisation | null {
    const orgs = useOrganisations();
    const pers = useCurPerson();
    if (!pers || !orgs) return null;
    if (!pers.OrganisationID) return null;
    return orgs.find(o => o.OrganisationID === pers.OrganisationID) || null
}
export function useCurPersonRegistrations(): Registration[] | null {
    const regs = useRegistrations();
    const curPerson = useCurPerson();
    if (!regs || !curPerson) return null
    return regs.filter(r => r.PersonID === curPerson.PersonID)
}
export function useCurMonth(): Month | null {
    return useSelector<State, Month | null>(s => s.curMonth)
}
export function useCurProject(): Project | null {
    const projs = useProjects();
    const curProjId = useSelector<State, string | null>(s => s.curProjectId)
    if (!curProjId || !projs) return null;
    return projs.find(p => p.ProjectID === curProjId) || null
}
export function useCurActivity(): Activity | null {
    const curActId = useSelector<State, string | null>(s => s.curActId)
    const projs = useProjects();

    if (!curActId || !projs) return null
    const projAlias = curActId.split(".")[0]
    const proj = projs.find(p => p.Alias == projAlias)
    if (!proj) return null
    return (proj.Activities || []).find(a => a.ActivityID == curActId) || null
}

export const actions = {
    user: {
        async initiateLogin(email: string) {
            return rpc("user.InitiateLogin", { Email: email })
        },
        async finalizeLogin(jwt: string) {
            localStorage.setItem("jwt", jwt)
            await Promise.all([
                actions.user.get(),
                actions.projects.get(),
                actions.organisations.get(),
                actions.persons.get(),
                actions.registrations.get()
            ])

            actions.selectPerson(store.getState().user?.PersonID || null);
        },
        async logout() {
            localStorage.removeItem("jwt");
            store.dispatch({ type: "SET_USER", value: null })
        },
        async get() {
            await rpc("user.Get")
                .then(r => store.dispatch({ type: "SET_USER", value: r }))
        },
        async updateDetails(values: UserUpdateDetailsParams) {
            await rpc("user.UpdateDetails", values)
            await actions.user.get()
            await actions.persons.get()
        },
        async generateStartskema(values: GenerateStartskemaParams) {
            await rpc("user.GenerateStartskema", values)
            await actions.user.get()
            await actions.persons.get()
        },
        async refreshStartskema() {
            await rpc("user.RefreshStartskema");
            await actions.user.get()
            await actions.persons.get()
        },
        async generateSlutskema(values: GenerateSlutskemaParams) {
            await rpc("user.GenerateSlutskema", values)
            await actions.user.get()
            await actions.persons.get()
        },
        async refreshSlutskema() {
            await rpc("user.RefreshSlutskema");
            await actions.user.get()
            await actions.persons.get()
        }
    },

    projects: {
        async get() {
            await rpc("projects.Get")
                .then(r => store.dispatch({ type: "SET_PROJECTS", value: r }))
        },
        async create(values: ProjectCreateParams) {
            await rpc("projects.Create", values)
            await actions.projects.get()
        },
        async update(values: ProjectUpdateParams) {
            await rpc("projects.Update", values)
            await actions.projects.get()
        },
        async createActivity(values: ActivityCreateParams) {
            await rpc("projects.CreateActivity", values)
            await actions.projects.get()
        },
        async updateActivity(values: ActivityUpdateParams) {
            await rpc("projects.UpdateActivity", values)
            await actions.projects.get()
        },
    },

    organisations: {
        async get() {
            await rpc("organisations.Get")
                .then(r => store.dispatch({ type: "SET_ORGANISATIONS", value: r }))
        },
        async updateDetails(values: OrganisationUpdateDetailsParams) {
            await rpc("organisations.UpdateDetails", values)
            await actions.organisations.get()
        },
        async sendAftale(orgId: string) {
            await rpc("organisations.SendAftale", { OrganisationID: orgId });
        },
        async refreshAftale(orgId: string) {
            await rpc("organisations.RefreshAftale", { OrganisationID: orgId });
            await actions.organisations.get()
        },
        async sendDeminimis(orgId: string) {
            await rpc("organisations.SendDeminimis", { OrganisationID: orgId });
        },
        async refreshDeminimis(orgId: string) {
            await rpc("organisations.RefreshDeminimis", { OrganisationID: orgId });
            await actions.organisations.get()
        },
        async inviteEmployee(values: OrganisationInviteEmployeeParams) {
            const pers: Person = await rpc("organisations.InviteEmployee", values);
            await actions.persons.get()
            return pers
        },
        async uninviteEmployee(personId: string) {
            await rpc("organisations.UninviteEmployee", { PersonID: personId })
            await actions.persons.get()
        },
        async resendInvite(personId: string) {
            await rpc("organisations.ResendInvite", { PersonID: personId })
        },
        async searchCVR(query: string) {
            return rpc("organisations.SearchCVR", { Query: query })
        },
        async listPUnits(cvr: string) {
            return rpc("organisations.ListPUnits", { CVR: cvr })
        },
        async create(values: OrganisationCreateParams) {
            const org: Organisation = await rpc("organisations.Create", values);
            await actions.organisations.get()
            return org;
        },
        async addToActivities(orgId: string, actIds: string[]) {
            await rpc("organisations.AddToActivities", { OrganisationID: orgId, ActivityIDs: actIds });
            await actions.organisations.get()
        },
        async getDocumentsDownloadLink(orgID: string) {
            return await rpc("organisations.GetDocumentsDownloadLink", { OrganisationID: orgID })
        },
        async setRoles(orgID: string, roles: Role[]) {
            await rpc("organisations.SetRoles", { OrganisationID: orgID, Roles: roles })
            await actions.organisations.get()
        },
        async getExportDownloadLink(bevilling: Bevilling) {
            return await rpc("organisations.GetExportDownloadLink", { Bevilling: bevilling })
        },
        async manualAftale(params: OrganisationManualAftaleParams) {
            const url = await rpc("organisations.ManualAftale", params)
            await actions.organisations.get()
            return url;
        }
    },

    persons: {
        async get() {
            await rpc("persons.Get")
                .then(r => store.dispatch({ type: "SET_PERSONS", value: r }))
        },
        async createAllocation(values: PersonCreateAllocationParams) {
            await rpc("persons.CreateAllocation", values)
            await actions.persons.get()
        },
        async refreshAllocation(personID: string, caseFile: number) {
            await rpc("persons.RefreshAllocation", { PersonID: personID, CaseFile: caseFile })
            await actions.persons.get()
        },
        async getAllocationDownloadLink(personID: string, caseFile: number): Promise<string> {
            return await rpc("persons.GetAllocationDownloadLink", { PersonID: personID, CaseFile: caseFile })
        },
        async updateGodkender(personId: string, godkender: Signer | null) {
            await rpc("persons.UpdateGodkender", { PersonID: personId, Godkender: godkender })
            await actions.user.get()
            await actions.persons.get()
        },
        async updatePeriod(values: PersonUpdatePeriodParams) {
            await rpc("persons.UpdatePeriod", values)
            await actions.persons.get()
        },
        async setRoles(persID: string, roles: Role[]) {
            await rpc("persons.SetRoles", { PersonID: persID, Roles: roles })
            await actions.persons.get()
        },
        async getExportDownloadLink(bevilling: Bevilling) {
            return await rpc("persons.GetExportDownloadLink", { Bevilling: bevilling })
        },
        async invite(values: PersonInviteParams) {
            const pers: Person = await rpc("persons.Invite", values);
            await actions.user.get()
            await actions.persons.get()
            return pers
        },
        async manualAllocation(params: PersonManualAllocationParams) {
            const url = await rpc("persons.ManualAllocation", params)
            await actions.persons.get()
            return url;
        },
        async addToActivities(personId: string, actIds: string[]) {
            await rpc("persons.AddToActivities", { PersonID: personId, ActivityIDs: actIds });
            await actions.persons.get()
        },
        async getAllAllocationDownloadLink() {
            return await rpc("persons.getAllAllocationDownloadLink")
        }
    },

    registrations: {
        async get() {
            await rpc("registrations.Get")
                .then(r => store.dispatch({ type: "SET_REGISTRATIONS", value: r }))
        },
        async createTicket(values: RegistrationCeateTicketParams) {
            await rpc("registrations.CreateTicket", values)
        },
        async updateLines(values: RegistrationsUpdateLinesParams) {
            await rpc("registrations.UpdateLines", values)
        },
        async submit(values: RegistrationSubmitParams) {
            await rpc("registrations.Submit", values)
            await actions.registrations.get()
        },
        async reject(values: RegistrationRejectParams) {
            await rpc("registrations.Reject", values)
            await actions.registrations.get()
        },
        async accept(values: RegistrationAcceptParams) {
            await rpc("registrations.Accept", values)
            await actions.registrations.get()
        },
        async unlock(values: RegistrationUnlockParams) {
            await rpc("registrations.Unlock", values)
            await actions.registrations.get()
        },
        async getLonseddelUploadLink(personId: string, month: Month): Promise<string> {
            return rpc("registrations.GetLonseddelUploadLink", { PersonID: personId, Month: month })
        },
        async getLonseddelDownloadLink(personId: string, month: Month): Promise<string> {
            return rpc("registrations.GetLonseddelDownloadLink", { PersonID: personId, Month: month })
        },
        async getTimeseddelDownloadLink(personId: string, month: Month, actID: string): Promise<string> {
            return rpc("registrations.GetTimeseddelDownloadLink", { PersonID: personId, Month: month, ActivityID: actID })
        },
        async refreshTimeseddel(regId: string) {
            await rpc("registrations.RefreshTimeseddel", { RegistrationID: regId })
            await actions.registrations.get()
        },
        async getExportDownloadLink(bevilling: Bevilling) {
            return await rpc("registrations.GetExportDownloadLink", { Bevilling: bevilling })
        },
        async getAllExportDownloadLink() {
            return await rpc("registrations.GetAllExportDownloadLink") 
        },
        async getImportUploadDataLink() {
            return await rpc("registrations.GetImportUploadDataLink")
        },
        async updateActivity(values: RegisterActivityUpdateParams) {
            await rpc("registrations.UpdateActivity", values)
        },
        async updateRegistrations(values: UpdateRegistrationsParams) {
            await rpc("registrations.updateRegistrations", values)
            await actions.registrations.get()
        },
        async addAllocToRegistrations(values: AddAllocToRegistrationsParams) {
            await rpc("registrations.addAllocToRegistrations", values)
            await actions.registrations.get()
        },
        async updateLonseddel(values: UpdateLønseddelParams) {
            await rpc("registrations.updateLonseddel", values)
            await actions.registrations.get()
        },      
    },

    test: {

    },


    selectProject: (projectId: string | null) => {
        store.dispatch({ type: "SET_CUR_PROJECT_ID", value: projectId })
    },
    selectActivity: (activityId: string | null) => {
        store.dispatch({ type: "SET_CUR_ACTIVITY_ID", value: activityId })
    },
    selectOrganisation(orgId: string | null) {
        store.dispatch({ type: "SET_CUR_ORGANISATION_ID", value: orgId })
    },
    selectPerson(persId: string | null) {
        store.dispatch({ type: "SET_CUR_PERSON_ID", value: persId })
    },
    selectMonth(month: Month | null) {
        store.dispatch({ type: "SET_CUR_MONTH", value: month })
    }
}


export type UserUpdateDetailsParams = {
    FirstName: string
    LastName: string
    CPR: string
    Mobile: string
    Ugenorm?: number
}
export type ProjectCreateParams = {
    Name: string
    Alias: string
    Bevilling: Bevilling
    ProjectManager: Signer
}
export type ProjectUpdateParams = {
    ProjectID: string
    Name?: string
    Bevilling?: Bevilling
    ProjectManager: Signer
}
export type ActivityCreateParams = {
    ActivityID: string
    Name: string
    Start?: Month | null
    End?: Month | null
    DeminimisEst?: number
    Workdescrs?: string[]
    ProjectManagement: boolean
}
export type ActivityUpdateParams = {
    ActivityID: string
    Name: string
    DeminimisEst?: number
    Workdescrs?: string[]
}
export type RegisterActivityUpdateParams = {
    ActivityID: string
    Alloc: Alloc
}
export type OrganisationUpdateDetailsParams = {
    OrganisationID: string
    Tegningsberettiget: Signer
    SMV: boolean
    Deminimis: DeminimisLine[]
}
export type OrganisationInviteEmployeeParams = {
    OrganisationID: string
    Email: string
    Start: Month | null
    End: Month | null
}
export type PersonUpdatePeriodParams = {
    PersonID: string
    Start: Month | null
    End: Month | null
}
export type OrganisationCreateParams = {
    Pno: string
    Type: OrganisationType
    Afregning: Afregning
    Start: Month | null
    End: Month | null
}
export type PersonCreateAllocationParams = {
    PersonID: string
    Lon: number
    Start: string
    Stilling: string
    Leder: Signer
    Activities: {
        ActivityID: string
        Alloc: Alloc
        Lines: string[]
    }[]
    Other: {
        JournalNr: string
        Project: string
        Alloc: Alloc
    }[]
}
export type RegistrationCeateTicketParams = {
    Subject: string
    Message: string
    PersonID: string
    Month: Month
}
export type RegistrationsUpdateLinesParams = {
    PersonID: string
    Month: Month
    Activities: {
        ActivityID: string
        Lines: RegistrationActivityLine[]
    }[]
}
export type UpdateRegistrationsParams = {
    PersonID: string
    Month: Month
    Activities: {
        ActivityID: string
        Alloc: Alloc
    }[]
}
export type UpdateLønseddelParams = {
    PersonID: string
    Month: Month
}
export type AddAllocToRegistrationsParams = {
    PersonID: string
    Month: Month
    Activities: {
        ActivityID: string
        Alloc: Alloc
    }[]
}
export type RegistrationSubmitParams = {
    PersonID: string
    Month: Month
    Lonseddel: boolean
    Activities: {
        ActivityID: string
        Lines: RegistrationActivityLine[]
    }[]
}
export type RegistrationRejectParams = {
    PersonID: string
    Month: Month
    Reason: string
}
export type RegistrationAcceptParams = {
    PersonID: string
    Month: Month,
    AM?: DKK,
    ATP?: DKK,
    Pension?: DKK,
    Ferie?: DKK
}
export type RegistrationUnlockParams = {
    PersonID: string
    Month: Month
}
export type OrganisationManualAftaleParams = {
    OrganisationID: string
    SignedAt: string
}
export type PersonManualAllocationParams = {
    PersonID: string
    SignedAt: string
    Start: string
    Activities: AllocationActivity[]
}
export type GenerateStartskemaParams = {
    Tilknytning: string
    Uddannelse: string
    Langtidsledig: boolean
}
export type GenerateSlutskemaParams = {
    Completed: boolean
    Tilknytning: string
    Langtidsledig: boolean
    HavdeCVR: string
    HarCVR: string
    Forbedring: boolean
}
export type PersonInviteParams = {
    Email: string
    Start: Month | null
    End: Month | null
}

export const store = createStore(
    reducer,
    /** @ts-ignore */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// @ts-ignore
window.actions = actions;