import { Modal, Form, DatePicker, Button, Row, message } from "antd";
import { useState } from "react";
import { actions, useCurOrganisation, useCurOrganisationEmployees, useCurPerson, useCurPersonRegistrations } from "../../../../utils/store";
import { getFirstSubmittedRegistration, getLastSubmittedRegistration, momentToMonth, monthToMoment, projectEnd, projectStart } from "../../../../utils/utils";

type Props = {
  visible?: boolean
  onClose: () => any
}

export default function EmployeePeriodModal(props: Props) {
  const curOrg = useCurOrganisation();
  const curPerson = useCurPerson();
  const curPersonRegs = useCurPersonRegistrations()
  const [loading, setLoading] = useState(false);

  if (!curPerson || !curOrg || !curPersonRegs) return null;

  async function onSubmit(values: any) {
    if (!curPerson) return;

    if (values.end && values.start && values.end.isBefore(values.start)) {
      message.error("Slutmåned kan ikke være før startmåned");
      return;
    }

    const start: moment.Moment | null = values.start;
    const end: moment.Moment | null = values.end;

    setLoading(true);
    try {
      await actions.persons.updatePeriod({
        PersonID: curPerson.PersonID,
        Start: start && momentToMonth(start),
        End: end && momentToMonth(end)
      })
        .then(r => { message.success(`Medarbejderen blev ændret`) });
      props.onClose();
    }
    finally {
      setLoading(false);
    }
  }

  const firstReg = getFirstSubmittedRegistration(curPersonRegs);
  const lastReg = getLastSubmittedRegistration(curPersonRegs);

  const start = monthToMoment(curOrg.Start || projectStart);
  const end = monthToMoment(curOrg.End || projectEnd);

  const personStart = curPerson.Start ? monthToMoment(curPerson.Start) : null
  const personEnd = curPerson.End ? monthToMoment(curPerson.End) : null

  return (
    <Modal destroyOnClose visible={props.visible} title={`Ændr periode for medarbejder - ${curPerson.FullName || curPerson.Email}`} onCancel={props.onClose} footer={null}>
      <Form labelCol={{ span: 6 }} onFinish={onSubmit}>
        <Form.Item name="start" label="Første måned" initialValue={personStart}>
          <DatePicker style={{ width: "100%" }} picker="month" format="MMMM YYYY" placeholder={start.format("MMMM YYYY")}
            disabledDate={v =>
              v.isBefore(start) || v.clone().date(0).isAfter(end) ||
              (firstReg ? v.clone().date(0).isAfter(monthToMoment(firstReg.Month)) : false)
            }
          />
        </Form.Item>

        <Form.Item name="end" label="Sidste måned" initialValue={personEnd}>
          <DatePicker style={{ width: "100%" }} picker="month" format="MMMM YYYY" placeholder={end.format("MMMM YYYY")}
            disabledDate={v =>
              v.isBefore(personStart) || v.isBefore(start) || v.isAfter(end) ||
              (lastReg ? v.isBefore(monthToMoment(lastReg.Month)) : false)
            }
          />
        </Form.Item>
        <Row justify="center">
          <Button htmlType="submit" type="primary" loading={loading}>Ændr periode</Button>
        </Row>
      </Form>
    </Modal>
  )
}