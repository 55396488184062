import { Col, Row, Select, Tag } from "antd";
import { useRef, useState } from "react";
import { actions, useOrganisations } from "../../../../utils/store";
import { timeout } from "../../../../utils/utils";

type Props = {
  value?: string | null
  onChange?: (value: string) => void
  disabled?: boolean
}

type Option = {
  CVR: string
  Name: string
  Address: string
  Zip: string
  City: string
  Pno?: string
}

export default function CVRSearch(props: Props) {
  const orgs = useOrganisations();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const reload = useRef<any>(null);

  async function onSearch(query: string) {
    if (!query) return;

    setOptions([]);

    if (reload.current) reload.current.cancel()
    reload.current = timeout(500);

    try {
      await reload.current.promise;
    } catch (e) {
      return;
    }

    try {
      const res = await actions.organisations.searchCVR(query)
      for (let i = 0; i < res.length; i++) {
        const existing = (orgs || []).find(o => o.CVR === res[i].CVR);
        if (existing) res[i] = existing;
      }
      setOptions(res);
      console.log(res);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Select
      showSearch
      placeholder="Søg på organisation"
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={onSearch}
      onChange={props.onChange}
      notFoundContent={null}
      loading={loading}
      optionLabelProp="title"
      style={{ width: "100%" }}
      value={props.value}
      disabled={loading || props.disabled}
    >
      {
        options.map(o =>
          <Select.Option key={o.CVR} title={
            <Row justify="space-between"><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col>CVR: {o.CVR}</Col></Row>
          }>
            <Row justify="space-between" style={{ lineHeight: 1.2 }}>
              <Col>
                <Row style={{ fontWeight: 600 }}>{o.Name}</Row>
                <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Address}</Row>
                <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Zip} {o.City}</Row>
              </Col>
              <Col style={{ fontSize: 12, }}>
                <Row justify="end">CVR: {o.CVR}</Row>
                {o.Pno && <Row><Tag color="blue" style={{ margin: "5px 0 0 0" }}>Deltager i Fyrtårnet</Tag></Row>}
              </Col>
            </Row>
          </Select.Option>
        )
      }
    </Select>
  )
}