import { Alert, Button, Col, Divider, Row, Tag } from "antd";
import { useState } from "react";
import { BiCheck, BiCommentDetail, BiErrorCircle, BiLock, BiPen, BiRefresh } from "react-icons/bi";
import { actions, useProjects } from "../../../../utils/store";
import { getLatestRegistrationLog, projectAliasFromActivity, signatureSigned } from "../../../../utils/utils";

type Props = {
    reg: Registration | null
}

export default function RegStatusAlert(props: Props) {
    const projs = useProjects();
    const [loading, setLoading] = useState(false);

    if (props.reg == null) return null;
    const regLog = getLatestRegistrationLog(props.reg);

    async function onUpdate() {
        if (!props.reg) return

        setLoading(true);
        try {
            await actions.registrations.refreshTimeseddel(props.reg.RegistrationID)
        }
        finally {
            setLoading(false);
        }
    }

    let text: string;
    let type: string;
    let icon: JSX.Element;
    let extra: JSX.Element | null = null;

    switch (props.reg.State) {
        case "rejected":
            text = "Registreringen er blevet afvist";
            type = "error"
            icon = <BiErrorCircle />;
            break;
        case "accepted":
            text = "Registreringen er godkendt"
            type = "success"
            icon = <BiCheck />;
            break;
        case "submitted":
            icon = <BiLock />
            text = "Registreringen er indsendt"
            type = "success"
            break;
        case "needs-signing":
            icon = <BiPen />
            type = "info"
            text = "Timeregistreringen er godkendt og skal underskrives"

            const signatures: { [caseFile: string]: SignableDocument } = {};

            for (const act of props.reg.Activities || []) {
                if (act.Timeseddel == null) continue;

                const existing = signatures[act.Timeseddel.CaseFile];
                if (existing) continue;

                signatures[act.Timeseddel.CaseFile] = act.Timeseddel;
            }

            const signatureValues = Object.values(signatures);

            extra = (
                <>
                    <Divider style={{ margin: "10px 0" }} />
                    {
                        signatureValues.map((sig, idx) => {
                            return (
                                <Row gutter={10} key={sig.CaseFile} justify="space-between" align="middle">
                                    <Col flex={1} style={{ fontWeight: "bold" }}>
                                        Timeseddel {signatureValues.length > 1 && idx + 1}
                                    </Col>
                                    <Col>
                                        <Row gutter={10}>
                                            {signatureSigned(sig)
                                                ? <Col><Row align="middle"><i>Underskrevet</i><BiCheck style={{ fontSize: 20, color: "green" }} /></Row></Col>
                                                :
                                                <>
                                                    <Col>
                                                        <Button type="primary" size="small" href={sig.Signatures?.[0].SignLink} target="_blank" loading={loading}>
                                                            <BiPen style={{ marginRight: 5, marginBottom: -2 }} /> Underskriv nu
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button type="primary" size="small" ghost loading={loading} onClick={() => onUpdate()}>
                                                            Opdater status
                                                        </Button>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Col>
                                    <Col>
                                    </Col>
                                    <Divider style={{ margin: "10px 0" }} />
                                </Row>
                            )
                        })
                    }
                </>
            )

            break;
        default: return null
    }

    return (
        <Alert
            style={{ marginBottom: 20 }}
            icon={icon} showIcon
            message={<Row style={{ fontWeight: 600 }}>{text}</Row>}
            description={
                (regLog?.Action == "rejected" && regLog?.Note)
                    ?
                    <>
                        <Row align="top" style={{ flexWrap: "nowrap" }} gutter={5}>
                            <Col><BiCommentDetail style={{ marginTop: 5 }} /> </Col>
                            <Col>{regLog.Note}</Col>
                        </Row>
                        {extra}
                    </>
                    : extra
            }
            type={type as any}
        />
    )
}