import { Button, Col, Form, Input, message, Modal, Result, Row, Select } from "antd";
import { useState } from "react";
import { actions, useUser } from "../../../../utils/store";

type Props = {
  visible: boolean
  onClose: () => any
}

export default function SlutskemaModal(props: Props) {
  const user = useUser();
  const [showLangtidsledig, setShowLangtidsledig] = useState(false);
  const [showCVR1, setShowCVR1] = useState(false);
  const [showCVR2, setShowCVR2] = useState(false);
  const [loading, setLoading] = useState(false);

  function onFormChange(_: any, v: any) {
    setShowLangtidsledig(v.tilknytning == "ledig")
    setShowCVR1(v.havdeEgenVirk == "ja")
    setShowCVR2(v.harEgenVirk == "ja")
  }

  async function onSubmit(v: any) {
    setLoading(true)
    try {
      await actions.user.generateSlutskema({
        Completed: v.completed == "ja",
        Tilknytning: v.tilknytning,
        Langtidsledig: v.langtidsledig == "ja",
        HavdeCVR: v.havdeCVR,
        HarCVR: v.harCVR,
        Forbedring: v.forbedring == "ja",
      })
      props?.onClose();
    }
    catch { }
    setLoading(false)
  }

  if (!user) return null;

  return (
    <Modal visible={props.visible} closable={false} footer={null} width={750} title="Slutskema" destroyOnClose>

      <Form layout="vertical" onValuesChange={onFormChange} onFinish={onSubmit}>
        <Form.Item name="completed" label="Fuldførte du din deltagelse?" rules={[{ required: true, message: "Påkrævet" }]}>
          <Select disabled={loading}>
            <Select.Option value="ja">Ja</Select.Option>
            <Select.Option value="nej">Nej, jeg afbrød før tid</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="tilknytning" label="Hvad var din tilknytning til arbejdsmarkedet, da du stoppede i projektet?" rules={[{ required: true, message: "Påkrævet" }]}>
          <Select disabled={loading}>
            <Select.Option key="beskaeftiget">Beskæftiget (lønmodtager eller selvstændig)</Select.Option>
            <Select.Option key="ledig">Ledig (aktivt arbejdssøgende og til rådighed for arbejdsmarkedet)</Select.Option>
            <Select.Option key="under-uddannelse">Under uddannelse (fuldtidsstuderende, herunder uddannelser med praktikforløb)</Select.Option>
            <Select.Option key="ikke-til-raadighed">Ikke til rådighed for arbejdsmarkedet og søger ikke aktivt arbejde</Select.Option>
          </Select>
        </Form.Item>
        {
          showLangtidsledig &&
          <Form.Item name="langtidsledig" rules={[{ required: true, message: "Påkrævet" }]} label={
            <Row>
              <Col>
                <div>
                  <strong>Hvis du er ledig og under 25 år:</strong> Har du været ledig længere end 6 måneder?
                </div>
                <div>
                  <strong>Hvis du er ledig og 25 år eller ældre:</strong> Har du været ledig længere end 12 måneder?
                </div>
              </Col>
            </Row>
          }>
            <Select disabled={loading}>
              <Select.Option key="ja">Ja</Select.Option>
              <Select.Option key="nej">Nej</Select.Option>
            </Select>
          </Form.Item>
        }
        <Form.Item name="havdeEgenVirk" label="Havde du egen virksomhed, da du startede i projektet?" rules={[{ required: true, message: "Påkrævet" }]}>
          <Select disabled={loading}>
            <Select.Option value="ja">Ja</Select.Option>
            <Select.Option value="nej">Nej</Select.Option>
          </Select>
        </Form.Item>
        {
          showCVR1 &&
          <Form.Item name="havdeCVR" label="Hvad er CVR-nummeret på din virksomhed?" rules={[{ required: true, message: "Skal være gyldigt CVR", len: 8 }]}>
            <Input disabled={loading} type="number" />
          </Form.Item>
        }
        <Form.Item name="harEgenVirk" label="Har du startet egen virksomhed undervejs i projektet eller umiddelbart efter din projektdeltagelse?" rules={[{ required: true, message: "Påkrævet" }]}>
          <Select disabled={loading}>
            <Select.Option value="ja">Ja</Select.Option>
            <Select.Option value="nej">Nej</Select.Option>
          </Select>
        </Form.Item>
        {
          showCVR2 &&
          <Form.Item name="harCVR" label="Hvad er CVR-nummeret på din virksomhed?" rules={[{ required: true, message: "Skal være gyldigt CVR", len: 8 }]}>
            <Input disabled={loading} type="number" />
          </Form.Item>
        }
        <Form.Item name="forbedring" label="Har projektet gjort dig bedre i stand til at starte og drive egen virksomhed?" rules={[{ required: true, message: "Påkrævet" }]}>
          <Select disabled={loading}>
            <Select.Option value="ja">Ja</Select.Option>
            <Select.Option value="nej">Nej</Select.Option>
          </Select>
        </Form.Item>

        <Row justify="center">
          <Button type="primary" htmlType="submit" loading={loading}>Indsend</Button>
        </Row>
      </Form>

    </Modal>
  )
}