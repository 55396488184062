import { Col, Drawer, Popover, Row, Tooltip } from "antd";
import moment from "moment";
import { BiCommentDetail, BiUser } from "react-icons/bi";
import { usePersons } from "../../utils/store";
import Log from "./Log";

type Props = {
  visible: boolean;
  onClose: () => any;
  log: LogEntry[];
}

export default function LogPane(props: Props) {
  const persons = usePersons();

  return (
    <Drawer destroyOnClose visible={props.visible} onClose={props.onClose} title="Log" width="min(100%, 600px)">
      <Log log={props.log} />
    </Drawer>
  )
}