import { Col, Row } from "antd";
// @ts-ignore
import logoInv from "../../../assets/logo-inv.png";
// @ts-ignore
import logo from "../../../assets/logo.png";

type Props = {
    light?: boolean
    size?: "large" | "medium" | "small"
}

export default function Logo(props: Props) {
    const imageSizes = {
        "large": 48,
        "medium": 48,
        "small": 44
    }
    const fontSizes = {
        "large": 16,
        "medium": 16,
        "small": 16
    }
    const imageSize = imageSizes[props.size || "small"]
    const fontSize = fontSizes[props.size || "small"]
    const image = props.light ? logoInv : logo

    return (
        <Row justify="center" style={{ flexDirection: "column" }} >
            <Row id="logo" style={{ color: props.light ? "white" : "black", fontSize: fontSize }}>proXsy</Row>
            <img src={image} style={{ height: imageSize }} />
        </Row >
    )
}