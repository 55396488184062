import { Button, Col, Input, Row, Select, Table, TableColumnsType } from "antd";

import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router";
import { actions, store, useOrganisations, usePersons, useRegistrations } from "../../../utils/store";
import { formatMonth, formatMonthShort, monthToMoment } from "../../../utils/utils";
import RegStatusTag from "../registrering/components/RegStatusTag";

export default function ImportExcelFileView() {
    async function onImportExcelData() {
        const url = await actions.registrations.getImportUploadDataLink();
        //window.open(url, "_blank");
    }

    // async function updateActivityData() {
    //     try{
    //         await actions.registrations.updateActivity({
    //             ActivityID: "soc.kursus",
    //             Alloc:125
    //         })
    //     }
    //     finally {
    //         console.log("Not Working")
    //     }
    // }

    return (
        <>
            <Button onClick={() => onImportExcelData()}>Import Excel Data</Button>
            {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
            {/*<Button onClick={() => updateActivityData()}>Update Activity</Button>*/}
        </>
    )
}