import { Button, Col, Row, Table, TableColumnsType, Tag } from "antd";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { BiDownload, BiPen } from "react-icons/bi";

import moment from "moment";
import { actions, useCurPerson, useRoles, useUser } from "../../../../utils/store";
import { getPersonAlloc, hasRole, momentToMonth, signatureSigned } from "../../../../utils/utils";
import EmployeeAllocAddModal from "./EmpAllocAddModal";
import SignatureStatus from "../../../shared/SignatureStatus";

type Props = {
  visible: boolean
  onClose: () => any
};

export default function EmployeeAllocModal(props: Props) {
  const roles = useRoles();
  const user = useUser();
  const curPerson = useCurPerson();
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false)

  if (!user || !curPerson) return null;

  function onRefresh(caseFile: number) {
    if (!curPerson) return
    setLoading(true)
    actions.persons.refreshAllocation(curPerson.PersonID, caseFile)
      .finally(() => setLoading(false))
  }

  async function onDownload(caseFile: number) {
    if (!curPerson) return
    setLoading(true)
    const link = await actions.persons.getAllocationDownloadLink(curPerson.PersonID, caseFile)
      .finally(() => setLoading(false))

    window.open(link, "_blank");
  }

  const mayDownload = hasRole(roles, "controller") || curPerson.PersonID === user.PersonID;

  const columns: TableColumnsType<Allocation> = [
    {
      title: "Start",
      sorter: (a, b) => moment(a.Start).diff(moment(b.Start)),
      defaultSortOrder: "ascend",
      render: (v, o) => {
        return `${moment(o.Start).format("DD. MMMM YYYY")}`
      },
    },
    {
      title: "Allokering",
      render: (v, o) => {
        const percentage = o.Activities?.reduce((p, c) => p + c.Alloc, 0);
        return `${percentage || "-"} %`
      }
    },
    {
      title: "Status",
      render: (v, o) => {
        if (!o.Contract) return null;
        const alloc = getPersonAlloc(momentToMonth(moment()), curPerson.Allocations || []);

        return (
          <Row gutter={10} align="middle">

            {o.State == "" &&
              <Col>
                <Row><SignatureStatus signature={o.Contract} details /></Row>
                <Row gutter={10} style={{ marginTop: 10 }}>
                  {(o.Contract.Signatures[0].Email == user.Email) &&
                    <Col>
                      <a href={o.Contract.Signatures[0].SignLink} target="_blank" rel="noreferrer">
                        <Button type="primary" size="small" style={{ cursor: "pointer", width: "100%" }} color="blue">
                          <Row align="middle" justify="center"><BiPen style={{ marginRight: 5 }} /> Underskriv</Row>
                        </Button>
                      </a>
                    </Col>
                  }
                  <Col>
                    {!signatureSigned(o.Contract) && <Button loading={loading} size="small" onClick={() => onRefresh(o.Contract.CaseFile)}>Opdater status</Button>}
                  </Col>
                </Row>
              </Col>
            }
            {o.State == "replaced" && <Tag>Erstattet</Tag>}
            {o.State == "rejected" && <Tag color="red">Afvist</Tag>}
            {o.State == "active" && (
              <Row>
                <Col>
                  {
                    o == alloc
                      ? <Tag color="green">Aktiv</Tag>
                      : <Tag title="Allokeringen er endnu ikke startet">Inaktiv - starter {moment(o.Start).format("\\d. D. MMM YYYY")}</Tag>
                  }
                </Col>
                <Col>
                  <Button disabled={!mayDownload || o.Contract.State !== "downloaded"} loading={loading} size="small" type="link" onClick={() => onDownload(o.Contract.CaseFile)}><BiDownload /></Button>
                </Col>
              </Row>
            )}
          </Row >
        )
      }
    }
  ];

  if (hasRole(roles, "admin")) {
    columns.push({
      title: "CaseFile",
      render: (v, o) => {
        const url = `https://${hasRole(roles, "test") ? "sandbox" : "app"}.penneo.com/casefiles/manage/${o.Contract.CaseFile}/details`
        return <a type="link" target="_blank" href={url}>{o.Contract.CaseFile}</a>
      }
    })
  }

  return (
    <Modal destroyOnClose visible={props.visible} title={`Ændr allokering for medarbejder - ${curPerson.FullName}`} onCancel={props.onClose} footer={null}>
      <Table size="small" columns={columns} dataSource={curPerson.Allocations || []} pagination={false} />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button onClick={() => setShowAdd(true)}>Tilføj</Button>
      </Row>

      <EmployeeAllocAddModal visible={showAdd} onClose={() => setShowAdd(false)} />
    </Modal>
  );
}