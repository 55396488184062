import { Row, Tag } from "antd"
import moment from "moment"
import { signatureFailed, signatureMissing, signaturePending } from "../../utils/utils"

type Props = {
  signature: SignableDocument | null
  details?: boolean
  fake?: boolean
}


export default function SignatureStatus(props: Props) {
  if (!props.fake) {
    if (signatureMissing(props.signature)) return <Tag color="orange">Mangler</Tag>
    if (signatureFailed(props.signature)) {
      if (props.signature?.State == "expired") return <Tag color="red">Frist udløbet</Tag>
      if (props.signature?.State == "rejected") return <Tag color="red">Underskrift afvist</Tag>
      return <Tag color="red">Underskrift fejlede</Tag>
    }
    if (signaturePending(props.signature)) return (
      <Tag color="orange">
        <Row>Afventer underskrift {props.details ? "fra:" : ""}</Row>
        {props.details &&
          <ul style={{ margin: 0 }}>
            {props.signature?.Signatures.filter(l => l.State == "pending").map(l => <li key={l.Email}>{l.Name}</li>)}
          </ul>
        }
      </Tag>
    )
  }

  const t = moment(props.signature?.SignedAt);
  let tooltip = "";
  if (t.isValid())
    tooltip = "Underskrevet d. " + t.format("\\d. D. MMMM YYYY \\k\\l. HH:MM")

  return <Tag color="green" title={tooltip}>Underskrevet</Tag>;
}