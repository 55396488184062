import { message } from "antd";
import { useEffect, useState } from "react";

import moment from "moment"

export function useWindowWidth() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
}


export async function rpc(method: string, params?: object): Promise<any> {
    const headers: HeadersInit = { "Content-Type": "application/json-rpc" }
    const jwt = localStorage.getItem("jwt")
    if (jwt) headers["Authorization"] = `Bearer ${jwt}`;

    const res = await fetch("/rpc", {
        headers,
        method: "POST",
        body: JSON.stringify({ "method": method, "params": params })
    })
        .then(r => r.json())
        .catch(e => {
            message.error(e.message);
            throw e;
        })

    if (res.error) {
        console.table(res.error.trace)
        message.error(res.error.message)
        throw new Error(res.error.message);
    }
    return res.result
}

export async function uploadFile(url: string, file: File) {
    await fetch(url, {
        method: "put",
        headers: { "Content-Type": file.type },
        body: file
    })
        .catch(e => {
            message.error(e.message)
            throw e;
        })
}

export function getPersonAlloc(month: Month, allocs: Allocation[] | null, onlyActive = true, allowNext = false): Allocation | null {
    let bestAlloc: Allocation | null = null;

    for (const alloc of allocs || []) {
        if (onlyActive) {
            if (alloc.State != "active") continue;
        }
        const start = momentToMonth(moment(alloc.Start));

        if (month.Year < start.Year || (month.Year == start.Year && month.Month < start.Month))
            continue;

        if (bestAlloc) {
            const bestStart = momentToMonth(moment(bestAlloc.Start));
            if (start.Year < bestStart.Year || (start.Year == bestStart.Year && start.Month < bestStart.Month))
                continue;
        }

        bestAlloc = alloc;
    }

    if (bestAlloc == null && allowNext) {
        for (const alloc of allocs || []) {
            const start = momentToMonth(moment(alloc.Start));

            // Ignore allocs that are in the past
            if (month.Year > start.Year || (month.Year == start.Year && month.Month > start.Month))
                continue;

            if (bestAlloc) {
                const bestStart = momentToMonth(moment(bestAlloc.Start));
                if (start.Year > bestStart.Year || (start.Year == bestStart.Year && start.Month > bestStart.Month))
                    continue;
            }

            bestAlloc = alloc;
        }
    }

    return bestAlloc;
}

function getSubmittedRegistrations(regs: Registration[]): Registration[] {
    const statuses: RegistrationState[] = ["missing", "submitted", "needs-signing", "accepted", "unlocked"];
    // filter (and clone) array
    regs = regs.filter(r => statuses.includes(r.State));
    // sort by month
    regs.sort((a, b) => monthToMoment(b.Month).diff(monthToMoment(a.Month)));
    return regs
}

export function getLastSubmittedRegistration(regs: Registration[]): Registration | null {
    regs = getSubmittedRegistrations(regs);
    return regs.length > 0 ? regs[0] : null
}
export function getFirstSubmittedRegistration(regs: Registration[]): Registration | null {
    regs = getSubmittedRegistrations(regs);
    return regs.length > 0 ? regs[regs.length - 1] : null
}

export function getLatestRegistrationLog(reg: Registration): LogEntry | null {
    if (!reg.Log?.length) return null;
    return [...reg.Log].sort((a, b) => moment(b.Time).diff(moment(a.Time)))[0]
}

export function monthToMoment(month: Month): moment.Moment {
    return moment(`${formatMonthShort(month)}-01`);
}

export function momentToMonth(m: moment.Moment): Month {
    return { Year: m.year(), Month: m.month() + 1 }
}

export function formatMonthShort(month: Month) {
    const yearStr = month.Year.toString();
    const monthStr = month.Month.toString().padStart(2, "0");
    return `${yearStr}-${monthStr}`
}

export function formatMonth(month: Month) {
    return monthToMoment(month).format("MMMM YYYY")
}

export const projectStart: Month = { Year: 2021, Month: 11 };
export const projectEnd: Month = { Year: 2023, Month: 10 };

export function timeout(ms: number) {
    let cancel = null
    const p = new Promise((res, rej) => {
        cancel = rej
        setTimeout(res, ms)
    });
    return { promise: p, cancel }
}

export function signatureMissing(sig: SignableDocument | null) {
    return sig === null || sig?.State === ""
}

export function signaturePending(sig: SignableDocument | null) {
    return sig?.State === "pending"
}

export function signatureSigned(sig: SignableDocument | null): boolean {
    return sig?.State === "signed" || sig?.State === "downloaded";
}

export function signatureFailed(sig: SignableDocument | null): boolean {
    return sig?.State === "expired" || sig?.State === "rejected" || sig?.State === "error";
}

export function projectAliasFromActivity(activityID: string): string {
    return activityID.split('.')[0];
}

export function hasRole(roles: Role[], role: Role) {
    const isAdmin = roles.includes("admin");

    if (role == "test") return roles.includes(role);
    else if (isAdmin) return true
    else if (role == "project-manager") {
        for (const r of roles) {
            if (r.startsWith("project-manager")) return true
        }
        return false
    }
    return roles.includes(role);
}