import { Button, Col, Collapse, Descriptions, Row, Table, TableColumnsType, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { actions, useCurPerson, useCurPersonOrganisation, useCurPersonRegistrations, usePersons, useRoles, useUser } from "../../../utils/store";
import { formatMonthShort, momentToMonth, monthToMoment, projectEnd, projectStart } from "../../../utils/utils";
import PersonHeader from "./components/PersonHeader";

import moment from "moment"
import RegStatusTag from "./components/RegStatusTag";
import { Link } from "react-router-dom";
import SignatureStatus from "../../shared/SignatureStatus";
import SlutskemaModal from "./components/SlutskemaModal";

export default function RegistreringEmail() {
  const params = useParams()
  const navigate = useNavigate();

  const user = useUser();
  const persons = usePersons();
  const curPerson = useCurPerson();
  const curPersonRegs = useCurPersonRegistrations();
  const curPersonOrg = useCurPersonOrganisation();

  const [showSlutskema, setShowSlutskema] = useState(false)
  const [loading, setLoading] = useState(false);

  async function onSlutskemaRefresh() {
    setLoading(true)
    try {
      await actions.user.refreshSlutskema()
    }
    catch { }
    setLoading(false)
  }

  useEffect(() => {
    if (!persons || !user) return

    actions.selectMonth(null);

    const newPerson = persons.find(p => p.Email === params.email);
    if (newPerson && newPerson.State == "ready") actions.selectPerson(newPerson.PersonID);
    else if (params.email !== user.Email) navigate(`/registrering/${user.Email}`)

  }, [params, persons, user])

  function CustomRow(rowProps: any, extra: any) {
    if (!curPerson) return null;
    const regId = rowProps["data-row-key"];
    const reg = regs.find(r => r.RegistrationID === regId);
    if (!reg) return null;

    const month = monthToMoment(reg.Month)
    const isFuture = month.isAfter(moment());

    const row = <tr {...rowProps} style={{ cursor: isFuture ? "default" : "pointer", opacity: isFuture ? 0.3 : 1 }} />
    if (isFuture) return row

    return (
      <tr
        {...rowProps}
        style={{ cursor: isFuture ? "default" : "pointer" }}
        onClick={() => reg ? navigate(`/registrering/${curPerson.Email}/${formatMonthShort(reg.Month)}`) : null}
      />
    );
  }

  if (!curPerson || !curPersonRegs) return null;
  const columns: TableColumnsType<Registration> = [
    {
      title: "Måned",
      key: "month",
      dataIndex: "Month",
      render: (v, o) => {
        const moment = monthToMoment(o.Month);
        const formatted = moment.format("MMMM YYYY");
        if (moment.isAfter()) return formatted;
        return <Link to={`/registrering/${curPerson.Email}/${formatMonthShort(o.Month)}`}>{formatted}</Link>
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "Status",
      width: 100,
      align: "left",
      render: (v, o) => <RegStatusTag reg={o} />
    }
  ]


  const regs: Registration[] = [];
  let curMonth = monthToMoment(curPerson.Start || curPersonOrg?.Start || projectStart);
  const endMonth = monthToMoment(curPerson.End || curPersonOrg?.End || projectEnd);
  let idx = 0;


  while (!curMonth.isAfter(endMonth)) {
    const existing = curPersonRegs.find(r => monthToMoment(r.Month).diff(curMonth, "d") === 0);
    if (existing != null) regs.push(existing);
    else {
      const reg: Registration = {
        RegistrationID: `${--idx}`,
        PersonID: curPerson.PersonID,
        State: "missing",
        Month: momentToMonth(curMonth),
        Activities: [],
        Lonseddel: false,
        Log: [],
      };

      if (monthToMoment(reg.Month).isAfter(moment()))
        reg.State = "waiting";
      regs.push(reg);
    }
    curMonth.add(1, "M");
  }

  return (
    <Col flex="0 1 800px">
      <Row>
        <PersonHeader
          onPersonSelect={p => p ? navigate(`/registrering/${p.Email}`) : navigate("/registrering")}
          onOrgClick={o => navigate(`/organisation/${o.CVR}`)}
        />
      </Row>
      <Row>
        <Col flex={1} >
          <Collapse style={{ fontSize: "0.75rem", marginTop: -32, pointerEvents: "none" }} ghost expandIconPosition="right" >
            <Collapse.Panel header={<Col flex={1}><Row justify="end" align="middle"><span style={{ pointerEvents: "all" }}>Detaljer</span></Row></Col>} key={1}>
              <Descriptions bordered size="small" style={{ pointerEvents: "all" }}>
                <Descriptions.Item label="Mobil">{curPerson.Mobile || "<mangler>"}</Descriptions.Item>
                {
                  curPersonOrg?.Afregning === "faktisk-lon" &&
                  <Descriptions.Item label="Ugenorm">{curPerson.Ugenorm || "<mangler>"}</Descriptions.Item>
                }
                {
                  curPersonOrg == null &&
                  <>
                    <Descriptions.Item label="Startskema">
                      <SignatureStatus signature={curPerson.Startskema} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Slutskema">
                      <SignatureStatus signature={curPerson.Slutskema} />
                    </Descriptions.Item>
                  </>
                }
                <Descriptions.Item label="CPR">
                  {
                    curPerson.CPR ? `${curPerson.CPR.substring(0, 6)}-${curPerson.CPR.substring(6)}` : "<skjult>"
                  }
                </Descriptions.Item>
                <Descriptions.Item label="State">{curPerson.State}</Descriptions.Item>
                <Descriptions.Item label="Person ID">{curPerson.PersonID}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col flex={1}>
          <Table size="small" columns={columns} dataSource={regs} rowKey="RegistrationID" pagination={false} components={{ body: { row: CustomRow } }} />
        </Col>
      </Row>
      <Row justify="center" gutter={10}>
        {
          (curPerson.PersonID == user?.PersonID && curPerson.Type == "solo" && curPerson.Slutskema?.SignedAt == null) &&
          <>
            {
              curPerson.Slutskema ?
                <>
                  <Col>
                    <Button loading={loading} type="primary" target="_blank" href={curPerson.Slutskema?.Signatures[0].SignLink}>Underskriv slutskema</Button>
                  </Col>
                  <Col>
                    <Button onClick={onSlutskemaRefresh} loading={loading}>Opdater status</Button>
                  </Col>
                </>
                :
                <Col>
                  <Tooltip title="Når din deltagelse i projektet er slut, skal du udfylde og underskrive et slutskema">
                    <Button type="primary" onClick={() => setShowSlutskema(true)}>Udfyld slutskema</Button>
                  </Tooltip>
                </Col>
            }
          </>

        }
        <SlutskemaModal visible={showSlutskema} onClose={() => setShowSlutskema(false)} />
      </Row>
    </Col>
  )
}