import { Button, Col, Form, message, Modal, Result, Row, Select } from "antd";
import { useState } from "react";
import { actions, useUser } from "../../../../utils/store";
import SplashLayout from "../../../layouts/SplashLayout";
import UserLayout from "../../../layouts/UserLayout";
import SignatureStatus from "../../../shared/SignatureStatus";

export default function StartskemaModal() {
  const user = useUser();
  const [showLangtidsledig, setShowLangtidsledig] = useState(false);
  const [loading, setLoading] = useState(false);

  function onFormChange(_: any, v: any) {
    setShowLangtidsledig(v.tilknytning == "ledig")
  }

  async function onSubmit(v: any) {
    setLoading(true)
    console.log("HERE", v)
    try {
      await actions.user.generateStartskema({
        Tilknytning: v.tilknytning,
        Langtidsledig: v.langtidsledig == "ja",
        Uddannelse: v.uddannelse,
      })
    }
    catch { }
    setLoading(false)
  }

  async function onRefresh() {
    setLoading(true)
    try {
      await actions.user.refreshStartskema()
    }
    catch { }
    setLoading(false)
  }

  if (!user) return null;

  return (
    <UserLayout>
      <SplashLayout>
        <Modal visible closable={false} footer={null} width={750} title="Startskema">
          {
            user.Startskema ?
              <>
                <Row justify="center">Startskemaet afventer din underskrift</Row>
                <Row justify="center" gutter={10} style={{ marginTop: 20 }}>
                  <Col>
                    <Button type="primary" href={user.Startskema.Signatures[0].SignLink} target="_blank">Underskriv nu</Button>
                  </Col>
                  <Col>
                    <Button onClick={onRefresh} loading={loading}>Opdater status</Button>
                  </Col>
                </Row>
              </>
              :
              <Form layout="vertical" onValuesChange={onFormChange} onFinish={onSubmit}>
                <Form.Item name="tilknytning" label="Hvad beskriver bedst din tilknytning til arbejdsmarkedet?" rules={[{ required: true, message: "Påkrævet" }]}>
                  <Select disabled={loading}>
                    <Select.Option key="beskaeftiget">Beskæftiget (lønmodtager eller selvstændig)</Select.Option>
                    <Select.Option key="ledig">Ledig (aktivt arbejdssøgende og til rådighed for arbejdsmarkedet)</Select.Option>
                    <Select.Option key="under-uddannelse">Under uddannelse (fuldtidsstuderende, herunder uddannelser med praktikforløb)</Select.Option>
                    <Select.Option key="ikke-til-raadighed">Ikke til rådighed for arbejdsmarkedet og søger ikke aktivt arbejde</Select.Option>
                  </Select>
                </Form.Item>
                {
                  showLangtidsledig &&
                  <Form.Item name="langtidsledig" rules={[{ required: true, message: "Påkrævet" }]} label={
                    <Row>
                      <Col>
                        <div>
                          <strong>Hvis du er ledig og under 25 år:</strong> Har du været ledig længere end 6 måneder?
                        </div>
                        <div>
                          <strong>Hvis du er ledig og 25 år eller ældre:</strong> Har du været ledig længere end 12 måneder?
                        </div>
                      </Col>
                    </Row>
                  }>
                    <Select disabled={loading}>
                      <Select.Option key="ja">Ja</Select.Option>
                      <Select.Option key="nej">Nej</Select.Option>
                    </Select>
                  </Form.Item>
                }
                <Form.Item name="uddannelse" label="Hvad er din højest fuldførte uddannelse ved projektstart?" rules={[{ required: true, message: "Påkrævet" }]}>
                  <Select disabled={loading}>
                    <Select.Option key="folkeskole">Grundskole/folkeskole 9. eller 10. klasse</Select.Option>
                    <Select.Option key="gymnasie">Gymnasial uddannelse (STX, HHX, HF, HTX)</Select.Option>
                    <Select.Option key="erhvervsskole">Erhvervsuddannelse (fx bager, tømrer, frisør, butiks- eller kontorassistent)</Select.Option>
                    <Select.Option key="kort">Kort videregående uddannelse (2-3 år, fx markedsøkonom, akademiøkonom, datamatiker, laborant, byggetekniker, merkonom)</Select.Option>
                    <Select.Option key="mellem">Mellemlang videregående uddannelse (3-4 år, fx diplomingeniør, sygeplejerske, folkeskolelærer, socialrådgiver, pædagog, bachelorgrad fra universitet)</Select.Option>
                    <Select.Option key="lang">Lang videregående uddannelse (4 år og mere, fx master, kandidat eller ph.d. fra universitetet, handelshøjskole, DTU eller tilsvarende)</Select.Option>
                    <Select.Option key="ingen">Har ikke gennemført nogen af ovenstående</Select.Option>
                  </Select>
                </Form.Item>

                <Row justify="center">
                  <Button type="primary" htmlType="submit" loading={loading}>Indsend</Button>
                </Row>
              </Form>
          }
        </Modal>
      </SplashLayout>
    </UserLayout>
  )
}