import { Col, Drawer, Popover, Row, Tooltip } from "antd";
import moment from "moment";
import { BiCommentDetail, BiUser } from "react-icons/bi";
import { usePersons } from "../../utils/store";

type Props = {
  log: LogEntry[];
}

export default function Log(props: Props) {
  const persons = usePersons();

  return (
    <>
      {
        [...(props.log || [])].sort((a, b) => moment(a.Time).diff(moment(b.Time)))
          .map((a, i) =>
            <Row key={i} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", padding: "5px 0" }}>
              <Col flex={1}>
                <Row justify="space-between" align="middle" >
                  <Col flex="0 0 80px">
                    <Tooltip title={moment(a.Time).format("LLLL")}>
                      {moment(a.Time).format("L")}
                    </Tooltip>
                  </Col>
                  <Col flex="0 0 33%">
                    <Row justify="start">
                      {
                        a.PersonID &&
                        <Tooltip title={`PersonID: ${a.PersonID}`}>
                          <BiUser style={{ marginBottom: -2, marginRight: 2 }} />
                          {persons?.find(p => p.PersonID == a.PersonID)?.FullName || null}
                        </Tooltip>
                      }
                    </Row>
                  </Col>
                  <Col flex="0 0 33%">
                    <Popover content={a.Note ? <pre style={{ margin: 0 }}>{a.Note}</pre> : null} placement="bottomRight" >
                      <Row justify="end" style={{ fontWeight: 600 }} gutter={5}>
                        <Col>{a.Action}
                        </Col>
                        <Col flex="0 0 20px">
                          {a.Note && <BiCommentDetail style={{ marginBottom: -2 }} />}
                        </Col>
                      </Row>
                    </Popover>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
      }
    </>
  )
}